import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { integrationsSelectors, integrationsActions } from "../redux/integrations";
import { reduxLoaderSelectors } from "../redux/redux-loader";
import Layout from "../components/layout/layout";
import StyledLoading from "../components/styled/StyledLoading";
import { StyledToolbar } from "../components/styled/ToolbarStyles";
import { ToolbarBox } from "../components/styled/DashboardStyles";
import { StyledTable } from "../components/styled/BlacklistStyles";
import { formatIntegrationTitle } from "../utils/formatter";
import TableOrderButton from "../components/styled/TableOrderButton";
import sortBy from "lodash/sortBy";

const StyledName = styled.div`
  display: flex;
  align-items: center;
  & img {
    max-width: 24px;
  }
  & p {
    margin: 0;
  }
`;

const Image = styled.div`
  background-color: #fff;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 32px;
  height: 32px;
`;

const Integrations = ({ getIntegrations, list, count, getIntegrationsState: { isDone, isLoading } }) => {
  const [installedOrder, setInstalledOrder] = useState("");
  const [visitsOrder, setVisitsOrder] = useState("");

  useEffect(() => {
    if (!isDone || list.length === 0) {
      getIntegrations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderHeader = () => (
    <thead>
      <tr>
        <th>Integration</th>
        <th>Description</th>
        <th>
          Installed count{" "}
          <TableOrderButton order={installedOrder} setOrder={setInstalledOrder} onClick={() => setVisitsOrder("")} />
        </th>
        <th>
          Visits count{" "}
          <TableOrderButton order={visitsOrder} setOrder={setVisitsOrder} onClick={() => setInstalledOrder("")} />
        </th>
      </tr>
    </thead>
  );

  const renderRow = ({ name, Icon, short_description, installed_count, visits_count }) => (
    <tr key={name}>
      <td>
        <StyledName>
          <Image>
            <img src={Icon} alt={name} />
          </Image>
          <p>{formatIntegrationTitle(name)}</p>
        </StyledName>
      </td>
      <td>{short_description}</td>
      <td>{installed_count}</td>
      <td>{visits_count}</td>
    </tr>
  );

  const renderBody = () => {
    let sorted = [];
    if (installedOrder === "" && visitsOrder === "") return list.map((integration) => renderRow(integration));
    if (visitsOrder !== "") {
      sorted = sortBy(list, (item) => (visitsOrder === "DESC" ? -item.visits_count : item.visits_count));
    }
    if (installedOrder !== "") {
      sorted = sortBy(list, (item) => (installedOrder === "DESC" ? -item.installed_count : item.installed_count));
    }
    return sorted.map((integration) => renderRow(integration));
  };

  return (
    <div>
      <StyledToolbar>
        <ToolbarBox>
          <p>Integrations</p>
          <p>{count}</p>
        </ToolbarBox>
      </StyledToolbar>
      {list.length > 0 && (
        <div style={{ overflowX: "auto", maxWidth: 1600, padding: "15px 0" }} className="mx-auto">
          <StyledTable style={{ minWidth: 1200 }} hover>
            {renderHeader()}
            <tbody>{renderBody()}</tbody>
          </StyledTable>
        </div>
      )}
      {isLoading && <StyledLoading />}
    </div>
  );
};

export default connect(
  (state, ownProps) => (state) => {
    return {
      list: integrationsSelectors.getIntegrationsList(state),
      count: integrationsSelectors.getIntegrationsCount(state),
      getIntegrationsState: reduxLoaderSelectors.getLoadingState(state, integrationsActions.getIntegrations),
    };
  },
  (dispatch) => {
    return bindActionCreators(
      {
        getIntegrations: integrationsActions.getIntegrations,
      },
      dispatch
    );
  }
)(Layout(Integrations));
