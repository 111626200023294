import React, { useState } from "react";
import GeneratedTokens from "../mixins/generatedTokens";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { accountSelectors, accountActions } from "../../redux/accounts";
import { MdMoreVert } from "react-icons/md";
import UpdateAgent from "../modals/UpdateAgent";
import { DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { DropdownList, ExtendStyledTable } from "./styles";
import DateParagraph from "../styled/DateParagraph";
import TableOrderButton from "../styled/TableOrderButton";
import sortBy from "lodash/sortBy";

const AgentsTable = ({ agents, ghostLogin, getGhostLogin, accountID }) => {
  const [isOpenList, setOpenList] = useState("");
  const [isModalAgentOpen, setOpenAgentModal] = useState("");
  const [order, setOrder] = useState("");

  const renderContent = (data) => {
    const sorted =
      order === ""
        ? data
        : sortBy(data, (item) => (order === "DESC" ? -+new Date(item.last_logged_in) : +new Date(item.last_logged_in)));

    return sorted.map(({ agent_id, creation_date, first_name, last_logged_in, last_name, login, role, status }) => (
      <tr key={agent_id}>
        <td style={{ maxWidth: 70 }}>{agent_id}</td>
        <td style={{ textOverflow: "initial", maxWidth: "fit-content" }}>{login}</td>
        <td>{`${first_name || "Empty"} ${last_name || ""}`}</td>
        <td>{status}</td>
        <td>{role}</td>
        <td style={{ width: 120 }}>
          <DateParagraph data={creation_date} />
        </td>
        <td style={{ width: 120 }}>
          <DateParagraph data={last_logged_in} />
        </td>
        <td style={{ position: "relative", overflow: "initial" }}>
          <DropdownList
            isOpen={isOpenList === login}
            toggle={() => setOpenList(isOpenList === login ? false : login)}
            size="sm"
          >
            <DropdownToggle>
              <MdMoreVert />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={(e) => getGhostLogin(login)} toggle={false}>
                Ghost login
                {ghostLogin.token && login === ghostLogin.email && (
                  <GeneratedTokens ghostLogin={ghostLogin} login={login} toast />
                )}
              </DropdownItem>
              <DropdownItem onClick={(e) => setOpenAgentModal(login)}>Update agent</DropdownItem>
            </DropdownMenu>
          </DropdownList>
        </td>
        {isModalAgentOpen === login && (
          <UpdateAgent
            agentRole={role}
            agentStatus={status}
            agentID={agent_id}
            login={login}
            isModalAgentOpen={isModalAgentOpen}
            setOpenAgentModal={setOpenAgentModal}
            accountID={accountID}
          />
        )}
      </tr>
    ));
  };

  return (
    <ExtendStyledTable hover>
      <thead>
        <tr>
          <th>Agent ID</th>
          <th>Login</th>
          <th>Name</th>
          <th>Status</th>
          <th>Role</th>
          <th>Creation date</th>
          <th>
            Last logged in <TableOrderButton order={order} setOrder={setOrder} />
          </th>
          <th>More</th>
        </tr>
      </thead>
      <tbody>{renderContent(agents)}</tbody>
    </ExtendStyledTable>
  );
};

export default connect(
  // mapStateToProps
  (state, ownProps) => (state) => {
    return {
      ghostLogin: accountSelectors.getGhostLoginData(state),
    };
  },
  // mapDispatchToProps
  (dispatch) => {
    return bindActionCreators(
      {
        getGhostLogin: accountActions.ghostLogin,
      },
      dispatch
    );
  }
)(AgentsTable);
