import React from "react";
import moment from "moment";
import { parseDate } from "./date";
import { FcCheckmark } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import _, { isBoolean } from "lodash";

const formatText = (text) => {
  const spaceRemoved = text.split("_").join(" ");
  return spaceRemoved.charAt(0).toUpperCase() + spaceRemoved.slice(1);
};

export const formatData = (data, ignoreStrings = false) => {
  if (typeof data === "number") {
    return data;
  }

  if (moment(data).isValid() && data?.length > 4) {
    return parseDate(data);
  }

  if (isBoolean(data)) {
    if (data) {
      return <FcCheckmark />;
    } else {
      return <IoMdClose />;
    }
  }

  if (typeof data === "string" && !ignoreStrings) {
    return formatText(data);
  }

  return data;
};

export const formatFromNow = (data, check = false, withoutRange = false) => {
  const date = moment(data).toArray();
  const daysFromNow = moment({ hours: 0 }).diff([date[0], date[1], date[2]], "days");
  const acceptRange = withoutRange ? true : _.inRange(daysFromNow, -31, 31);

  if (check && moment(data).isValid()) {
    if (daysFromNow <= 0) return "valid";
  }

  if (moment(data).isValid() && data.length > 4 && acceptRange) {
    if (daysFromNow >= 0) {
      switch (daysFromNow) {
        case 0:
          return `today`;
        case 1:
          return `yesterday`;
        default:
          return `${daysFromNow} days ago`;
      }
    } else {
      switch (daysFromNow) {
        case -1:
          return `tommorow`;
        default:
          return `in ${Math.abs(daysFromNow)} days`;
      }
    }
  }
  return parseDate(data);
};

export const formatIntegrationTitle = (title) => {
  return (
    title &&
    title
      .split("-")
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1, item.length))
      .join(" ")
  );
};
