import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formatData } from "../../utils/formatter";
import { MoreInfo, CustomTippy, ExtendStyledTable } from "./styles";
import DateParagraph from "../styled/DateParagraph";
import { integrationsSelectors, integrationsActions } from "../../redux/integrations";
import { reduxLoaderSelectors } from "../../redux/redux-loader";
import Tippy from "@tippyjs/react";
import { formatIntegrationTitle } from "../../utils/formatter";
import TableOrderButton from "../styled/TableOrderButton";
import sortBy from "lodash/sortBy";

const WebsiteTable = ({ websites, imageData, getIntegrations, getIntegrationsState: { isDone } }) => {
  const [order, setOrder] = useState("");

  useEffect(() => {
    if (!isDone || imageData.length === 0) {
      getIntegrations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTechnologies = (technologies) => {
    if (!imageData || imageData.length === 0) return <p>-</p>;
    let activeTechnologies = [];
    // eslint-disable-next-line array-callback-return
    Object.entries(technologies).map((item) => {
      if (item[1]) {
        activeTechnologies.push(imageData.find(({ name }) => name === item[0]));
      }
    });
    return activeTechnologies.length > 0 ? (
      activeTechnologies.map((image) => (
        <Tippy content={formatIntegrationTitle(image?.name)}>
          <img
            style={{ maxWidth: 16, marginRight: 8, cursor: "pointer" }}
            className="img-fluid"
            src={image?.icon}
            alt="icon"
          />
        </Tippy>
      ))
    ) : (
      <p>-</p>
    );
  };

  const renderContent = (data) => {
    const sorted =
      order === ""
        ? data
        : sortBy(data, (item) =>
            order === "DESC" ? -+new Date(item.last_data_received) : +new Date(item.last_data_received)
          );
    return sorted.map((accountData) => (
      <tr key={accountData.website_id}>
        <td style={{ maxWidth: 70 }}>{accountData.website_id}</td>
        <td>
          <a
            href={`https://${accountData.host}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            {accountData.host.toString()}
          </a>
        </td>
        <td>{renderTechnologies(accountData.technologies)}</td>
        <td>
          <DateParagraph data={accountData.last_data_received} />
        </td>
        <td>
          {accountData.recording_rules.list !== null ? (
            <CustomTippy
              theme="light"
              content={accountData.recording_rules.list.map((el, idx) => (
                <div key={idx}>
                  <p>{`Match type: ${el.match_type} `}</p>
                  <p>{`Type: ${el.type} `}</p>
                  <p>{`Operator: ${el.operator} `}</p>
                  <p>{`Value: ${el.value} `}</p>
                </div>
              ))}
              hideOnClick
            >
              <MoreInfo>See more</MoreInfo>
            </CustomTippy>
          ) : (
            <p>-</p>
          )}
        </td>
        <td>{formatData(accountData.devtools)}</td>
        <td>{formatData(accountData.tracking_enabled)}</td>
      </tr>
    ));
  };

  return (
    <>
      {(websites || []).length ? (
        <ExtendStyledTable hover>
          <thead>
            <tr>
              <th>Website ID</th>
              <th>Host</th>
              <th>Technology</th>
              <th>
                Last data received <TableOrderButton order={order} setOrder={setOrder} />
              </th>
              <th>Recording rules</th>
              <th>Devtools</th>
              <th>Tracking en.</th>
            </tr>
          </thead>
          <tbody>{renderContent(websites)}</tbody>
        </ExtendStyledTable>
      ) : (
        <p>
          Empty{" "}
          <span role="img" aria-label="emoji">
            💁‍♂️
          </span>
        </p>
      )}{" "}
    </>
  );
};

export default connect(
  // mapStateToProps
  (state, ownProps) => (state) => {
    return {
      imageData: integrationsSelectors.getIntegrationsImagesData(state),
      getIntegrationsState: reduxLoaderSelectors.getLoadingState(state, integrationsActions.getIntegrations),
    };
  },
  (dispatch) => {
    return bindActionCreators(
      {
        getIntegrations: integrationsActions.getIntegrations,
      },
      dispatch
    );
  }
)(WebsiteTable);
