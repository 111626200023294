const getAccountsList = (state) => {
  return state.accounts.list;
};
const getAccount = (state) => {
  return state.accounts.accountData;
};
const getAccountsCount = (state) => {
  return state.accounts.count;
};
const getSearchEmail = (state) => {
  return state.accounts.email;
};
const getCurrentPage = (state) => {
  return state.accounts.currentPage;
};
const getGhostLoginData = (state) => {
  return state.accounts.ghostLogin;
};
const getAccountsErrors = (state) => {
  return state.accounts.error;
};
const getAllPlans = (state) => {
  return state.accounts.plans;
};
const getPricingModel = (state) => {
  return state.accounts.pricingModel;
};
const getFilters = (state) => {
  return state.accounts.filters;
};
const getSorting = (state) => {
  return state.accounts.sorting;
};
const getLimit = (state) => {
  return state.accounts.limit;
};
const getESStats = (state) => {
  return state.accounts.accountData.esStats;
};
const getAccountLogs = (state) => {
  return state.accounts.accountData.logsStats;
};
const getAccountProperties = (state) => {
  return state.accounts.accountData.accountProperties;
};
const getApiErrors = (state) => {
  return state.accounts.accountData.apiErrors;
};

export default {
  getAccountsList,
  getSearchEmail,
  getAccountsCount,
  getCurrentPage,
  getGhostLoginData,
  getAccountsErrors,
  getAccount,
  getAllPlans,
  getPricingModel,
  getFilters,
  getLimit,
  getSorting,
  getESStats,
  getAccountProperties,
  getApiErrors,
  getAccountLogs,
};
