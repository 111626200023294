import styled from "styled-components";
import { Dropdown } from "reactstrap";
import { StyledTable } from "../styled/BlacklistStyles";
import Tippy from "@tippyjs/react";

export const AccountInfo = styled.div`
  & p:first-child {
    font-size: 12px;
    color: #807e8d;
    margin-bottom: 2px;
  }
  & p:last-child {
    margin-bottom: 6px;
    font-size: 14px;
  }
`;

export const MoreAccountInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #333;
  &:last-child {
    border-bottom: none;
  }
  & p {
    margin: 8px 0;
    font-size: 14px;
  }
  & p:first-child {
    font-size: 12px;
    color: #807e8d;
  }

  & p:last-child,
  & a:last-child {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 70%;
    white-space: nowrap;
    &:hover {
      text-overflow: initial;
      white-space: initial;
      overflow: auto;
      word-break: break-all;
    }
  }
`;

export const DropdownList = styled(Dropdown)`
  & button {
    cursor: pointer;
  }
  & .dropdown-menu {
    border: 1px solid #000;
    border-radius: 4px;
    & .dropdown-item {
      font-size: 14px;
    }
  }
  & .btn.btn-secondary {
    background-color: transparent;
    border: none;
    & svg {
      fill: #fff;
    }
  }
`;

export const ExtendStyledTable = styled(StyledTable)`
  min-width: auto;
  width: 100%;
  margin-bottom: 32px;
  overflow-x: scroll;
  height: auto;
  ${props =>  props.wordBreak ? `word-break: ${props.wordBreak};` : ""}

  & p {
    margin-bottom: 0;
    line-height: normal;
  }
  & .green {
    color: #62c462;
  }
  &.table {
    width: 100%;
    margin: 0 0 20px;

    & tr td {
      padding: 6px 8px !important;
      font-size: 11px;
      text-overflow: ellipsis;
      max-width: 190px;
      overflow: hidden;
      ${props =>  props.whiteSpace ? `white-space: ${props.whiteSpace};` : "white-space: nowrap;"}
      & svg {
        font-size: 16px;
        transform: translateY(3px);
      }
      & div.dropdown .btn svg {
        font-size: 18px;
      }
    }
    & tr th {
      padding: 8px !important;
      font-size: 11px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const MoreInfo = styled.p`
  cursor: pointer;
  font-size: 11px !important;
  margin: 0;
  text-decoration: underline;
`;

export const CustomTippy = styled(Tippy)`
  & .tippy-content div div {
    position: relative;
    padding-bottom: 4px;
    margin-bottom: 4px;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 90%;
      height: 1px;
      background-color: #ddd;
    }
    & p {
      margin: 0 0 4px;
      font-size: 12px;
    }
  }
  & .tippy-content div div:last-of-type {
    position: static;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

export const AccountDesc = styled.div`
  display: flex;
  flex-direction: column;
`;
