import React, {useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Link, withRouter} from "react-router-dom";
import {Alert, Nav} from "reactstrap";
import * as queryString from "querystring";

import Layout from "../components/layout/layout";
import * as Styled from "../components/styled/FormStyles";
import StyledButton from "../components/styled/StyledButton";
import {StyledForm} from "../components/modals/UpdateAccount";
import {Content, StyledNavItem} from "../components/accountDetails/AccountDetails";
import recordingActions from "../redux/recording/actions";
import {reduxLoaderSelectors} from "../redux/redux-loader";
import StyledLoading from "../components/styled/StyledLoading";

const tabs = [
    {
        id: "add-demo-recording",
        label: "Add demo recording",
    },
    {
        id: "add-issue-session-to-vault",
        label: "Add issue session to vault"
    }
];

function FormAddDemoRecording({createDemoRecording, location}) {
    const params = new URLSearchParams(location.search);

    const now = new Date();
    const date = `${now.toISOString().slice(0, 10).replaceAll("-", "")}${now
        .toTimeString()
        .slice(0, 8)
        .replaceAll(":", "")}`;

    const [accountID, setAccountID] = useState(params.get("accountID") || "");
    const [visitorID, setVisitorID] = useState(params.get("visitorID") || "");
    const [sessionID, setSessionID] = useState(params.get("sessionID") || "");
    const [namespace, setNamespace] = useState("demo-" + date);

    const onSubmit = (e) => {
        e.preventDefault();

        createDemoRecording({accountID, visitorID, sessionID, namespace});
    };


    return <StyledForm onSubmit={onSubmit}>
        <Styled.LabelInput htmlFor="accountID">
            <span>AccountID:</span>
            <input
                id="accountID"
                type="text"
                value={accountID}
                onChange={(e) => setAccountID(e.target.value)}
            />{" "}
        </Styled.LabelInput>

        <Styled.LabelInput htmlFor="visitorID">
            <span>VisitorID:</span>
            <input
                id="visitorID"
                type="text"
                value={visitorID}
                onChange={(e) => setVisitorID(e.target.value)}
            />{" "}
        </Styled.LabelInput>

        <Styled.LabelInput htmlFor="sessionID">
            <span>SessionID:</span>
            <input
                id="sessionID"
                type="text"
                value={sessionID}
                onChange={(e) => setSessionID(e.target.value)}
            />{" "}
        </Styled.LabelInput>

        <Styled.LabelInput htmlFor="namespace">
                  <span>
                    Namespace:
                    <div style={{fontSize: 11}}>"demo-webapp" for webapp</div>
                  </span>
            <input id="name" type="text" value={namespace} onChange={(e) => setNamespace(e.target.value)}/>
        </Styled.LabelInput>

        <StyledButton
            loading
            as="button"
            secondary
            type="submit"
            style={{margin: "35px auto 0", border: "none"}}
        >
            Create
        </StyledButton>
    </StyledForm>
}

function FormAddIssueSessionVault({location, createIssueSessionVault}) {
    const params = new URLSearchParams(location.search);

    const [accountID, setAccountID] = useState(params.get("accountID") || "");
    const [visitorID, setVisitorID] = useState(params.get("visitorID") || "");
    const [sessionID, setSessionID] = useState(params.get("sessionID") || "");

    const onSubmit = async (e) => {
        e.preventDefault();

        createIssueSessionVault({
            accountID,
            visitorID,
            sessionID
        })
    };

    return <StyledForm onSubmit={onSubmit}>
        <Styled.LabelInput htmlFor="accountID">
            <span>AccountID:</span>
            <input
                id="accountID"
                type="text"
                value={accountID}
                onChange={(e) => setAccountID(e.target.value)}
            />{" "}
        </Styled.LabelInput>

        <Styled.LabelInput htmlFor="visitorID">
            <span>VisitorID:</span>
            <input
                id="visitorID"
                type="text"
                value={visitorID}
                onChange={(e) => setVisitorID(e.target.value)}
            />{" "}
        </Styled.LabelInput>

        <Styled.LabelInput htmlFor="sessionID">
            <span>SessionID:</span>
            <input
                id="sessionID"
                type="text"
                value={sessionID}
                onChange={(e) => setSessionID(e.target.value)}
            />{" "}
        </Styled.LabelInput>

        <StyledButton
            loading
            as="button"
            secondary
            type="submit"
            style={{margin: "35px auto 0", border: "none"}}
        >
            Create
        </StyledButton>
    </StyledForm>
}

const Tools = ({
                   location,

                   createDemoRecording,
                   createIssueSessionVault,

                   createDemoRecordingState,
                   createIssueSessionVaultState,
               }) => {
    const params = queryString.parse(window.location.search.replace("?", ""))

    const isLoading = createDemoRecordingState?.isLoading || createIssueSessionVaultState?.isLoading
    const isError = createDemoRecordingState?.isError || createIssueSessionVaultState?.isError

    function ActiveTab() {
        switch (params.tab) {
            case "add-demo-recording": {
                return <FormAddDemoRecording createDemoRecording={createDemoRecording} location={location}/>
            }
            case "add-issue-session-to-vault": {
                return <FormAddIssueSessionVault createIssueSessionVault={createIssueSessionVault} location={location}/>
            }
            default: {
                return <FormAddDemoRecording createDemoRecording={createDemoRecording} location={location}/>
            }
        }
    }

    return (
        <div className="container-fluid" style={{marginTop: 24}}>
            <div className="content">
                <Nav tabs>
                    {tabs.map((tab, i) => (
                        <StyledNavItem key={tab.id}>
                            <Link
                                style={{padding: 20, color: params.tab === tab.id ? "#4663f6" : undefined}}
                                to={`/tools?tab=${tab.id}`}
                            >
                                {tab.label}
                            </Link>
                        </StyledNavItem>
                    ))}
                </Nav>
                {isError && (
                    <Alert style={{marginTop: 20}} color="danger">
                        Something went wrong
                    </Alert>
                )}
                {isLoading && <StyledLoading/>}
                <Content>
                    <ActiveTab/>
                </Content>
            </div>
        </div>
    );
};

export default connect(
    (state) => ({
        createDemoRecordingState: reduxLoaderSelectors.getLoadingState(state, recordingActions.createDemoRecording),
        createIssueSessionVaultState: reduxLoaderSelectors.getLoadingState(state, recordingActions.createIssueSessionVault),
    }),
    // mapDispatchToProps
    (dispatch) => {
        return bindActionCreators(
            {
                createDemoRecording: recordingActions.createDemoRecording,
                createIssueSessionVault: recordingActions.createIssueSessionVault
            },
            dispatch
        );
    }
)(withRouter(Layout(Tools)));
