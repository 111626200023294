import { all, fork } from "redux-saga/effects";
import { createRequest } from "../utils";
import blacklistActions from "./actions";
import api from "../../utils/api";

const getBlacklistRequest = createRequest(blacklistActions.GET_BLACKLIST, api.get, action => {
  return { url: "/blacklist" };
});

const deleteBlacklistItemRequest = createRequest(blacklistActions.DELETE_BLACKLIST_ITEM, api.delete, action => {
  return { url: `/blacklist/${action.payload.id}` };
});

const postBlacklistItemRequest = createRequest(blacklistActions.POST_BLACKLIST_ITEM, api.post, action => {
  return { url: `/blacklist`, body: action.payload.data };
});

export default function* rootSaga() {
  yield all([fork(getBlacklistRequest), fork(deleteBlacklistItemRequest), fork(postBlacklistItemRequest)]);
}
