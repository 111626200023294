import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { accountSelectors, accountActions } from "../../redux/accounts/index";
import { reduxLoaderSelectors } from "../../redux/redux-loader";
import { ExtendStyledTable } from "../tables/styles";
import DateParagraph from "../styled/DateParagraph";
import { Box } from "./Info";
import sortBy from "lodash/sortBy";
import StyledLoading from "../../components/styled/StyledLoading";
import TableOrderButton from "../styled/TableOrderButton";
import styled from "styled-components";

export const Table = styled.tr`
  .word-wrap {
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal !important;
    width: 40%;
  }
`;

const LogsStats = ({ accountID, logsStats, getAccountLogs, logsStatsState: { isError, isLoading } }) => {
  useEffect(() => {
    if (logsStats === undefined) {
      getAccountLogs(accountID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [order, setOrder] = useState("DESC");

  const renderContent = (data) => {
    const sorted = order === "" ? data : sortBy(data, (item) => (order === "DESC" ? -+new Date(item.creation_date) : +new Date(item.creation_date)));

    return sorted.map(({ agent_login, action, value, creation_date }) => (
      <Table>
        <td>{agent_login ? agent_login : "-"}</td>
        <td>{action ? action : "-"}</td>
        <td className="word-wrap">{value ? value : "-"}</td>
        <td style={{ width: 120 }}>
          <DateParagraph data={creation_date} />
        </td>
      </Table>
    ));
  };

  return (
    <Box>
      <h2>Logs</h2>
      {isLoading ? (
        <StyledLoading />
      ) : (
        <>
          {logsStats === undefined ? (
            <p>{isError ? "Failed to fetch :(" : "Loading..."}</p>
          ) : (
            <div>
              <ExtendStyledTable>
                <thead>
                  <tr>
                    <th>Login</th>
                    <th>Action</th>
                    <th>Value</th>
                    <th>
                      Date <TableOrderButton order={order} setOrder={setOrder} />
                    </th>
                  </tr>
                </thead>
                <tbody>{renderContent(logsStats)}</tbody>
              </ExtendStyledTable>
            </div>
          )}
        </>
      )}
    </Box>
  );
};

export default connect(
  // mapStateToProps
  (state, ownProps) => (state) => {
    return {
      logsStats: accountSelectors.getAccountLogs(state),
      logsStatsState: reduxLoaderSelectors.getLoadingState(state, accountActions.getAccountLogs),
    };
  },
  // mapDispatchToProps
  (dispatch) => {
    return bindActionCreators(
      {
        getAccountLogs: accountActions.getAccountLogs,
      },
      dispatch
    );
  }
)(LogsStats);
