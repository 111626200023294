import { handleActions, combineActions } from "redux-actions";
import accountActions from "./actions";
import update from "immutability-helper";
import cogoToast from "cogo-toast";
import queryString from "query-string";

const { sortby, order, page, value, filter_type, filter_value } = queryString.parse(window.location.search);

const defaultState = {
  list: [],
  count: 0,
  email: value && value.length > 0 ? value : "",
  currentPage: page ? parseInt(page) : 0,
  limit: 12,
  ghostLogin: {
    email: "",
    token: "",
  },
  error: "",
  accountData: null,
  plans: null,
  pricingModel: null,
  filters: {
    type: filter_type && filter_type.length > 0 ? filter_type : "",
    value: filter_value && filter_value.length > 0 ? filter_value : "",
  },
  sorting: {
    by: sortby && sortby.length > 0 ? sortby : "creation_date",
    order: order && order.length > 0 ? order : "DESC",
  },
};

const accountReducer = handleActions(
  {
    [accountActions.SET_FILTER_TYPE]: (state, action) => {
      const { filterType } = action.payload;
      return update(state, { filters: { type: { $set: filterType } } });
    },
    [accountActions.SET_FILTER_VALUE]: (state, action) => {
      const { filterValue } = action.payload;
      return update(state, { filters: { value: { $set: filterValue } } });
    },
    [accountActions.SET_SORTBY]: (state, action) => {
      const { sort } = action.payload;
      return update(state, { sorting: { by: { $set: sort } } });
    },
    [accountActions.SET_SORT_ORDER]: (state, action) => {
      const { sortOrder } = action.payload;
      return update(state, { sorting: { order: { $set: sortOrder } } });
    },
    [accountActions.CLEAR_TOKEN]: (state, action) => {
      return update(state, { ghostLogin: { token: { $set: null }, email: { $set: null } } });
    },
    [accountActions.SEARCH_EMAIL]: (state, action) => {
      const { email } = action.payload;
      return update(state, { email: { $set: email } });
    },
    [accountActions.CURRENT_PAGE]: (state, action) => {
      const { page } = action.payload;
      return update(state, { currentPage: { $set: page } });
    },
    [accountActions.GHOST_LOGIN]: (state, action) => {
      const { login } = action.payload;
      cogoToast.loading("Loading", { hideAfter: 0.8 });
      return update(state, { ghostLogin: { email: { $set: login } } });
    },
    [accountActions.GET_ACCOUNTS_SUCCESS]: (state, action) => {
      const { accounts, count } = action.payload;
      const {
        currentPage,
        filters: { type, value },
        sorting: { by, order },
        email,
        list,
      } = state;
      if (list.length !== 1) {
        window.history.pushState("", "", `/?page=${currentPage + 1}&filter_type=${type}&filter_value=${value}&sortby=${by}&order=${order}&value=${email}`);
      }
      if (accounts.length === 0) cogoToast.error("Nothing found");
      return update(state, { list: { $set: accounts }, count: { $set: count } });
    },
    [accountActions.GET_ACCOUNT_SUCCESS]: (state, action) => {
      return update(state, { accountData: { $set: action.payload } });
    },
    [accountActions.GET_PLANS_SUCCESS]: (state, action) => {
      const { plans, pricing_model } = action.payload;
      return update(state, { plans: { $set: plans }, pricingModel: { $set: pricing_model } });
    },
    [accountActions.GHOST_LOGIN_SUCCESS]: (state, action) => {
      const { token } = action.payload;
      return update(state, { ghostLogin: { token: { $set: token } } });
    },
    [accountActions.UPDATE_AGENT_SUCCESS]: (state, action) => {
      cogoToast.success("Agent updated");
      return;
    },
    [accountActions.GET_ES_STATS_SUCCESS]: (state, action) => {
      return {
        ...state,
        accountData: {
          ...state.accountData,
          esStats: {
            stats: action.payload.stats,
            total: action.payload.total,
          },
        },
      };
    },
    [accountActions.GET_ACCOUNT_PROPERTIES_SUCCESS]: (state, action) => {
      return {
        ...state,
        accountData: {
          ...state.accountData,
          accountProperties: {
            ...action.payload,
          },
        },
      };
    },
    [accountActions.GET_API_ERRORS_SUCCESS]: (state, action) => {
      return {
        ...state,
        accountData: {
          ...state.accountData,
          apiErrors: action.payload.response,
        },
      };
    },
    [accountActions.GET_ACCOUNT_LOGS_SUCCESS]: (state, action) => {
      return {
        ...state,
        accountData: {
          ...state.accountData,
          logsStats: action.payload.response,
        },
      };
    },
    [combineActions(accountActions.UPDATE_ACCOUNT_QUOTAS_SUCCESS, accountActions.UPDATE_ACCOUNT_SESSION_QUOTAS_BONUS_SUCCESS, accountActions.UPDATE_ACCOUNT_SUCCESS, accountActions.UPDATE_PLAN_SUCCESS, accountActions.ADD_ACCOUNT_PROPERTY_SUCCESS)]: (state, action) => {
      if (action.payload.error) {
        const { message } = action.payload.error;
        cogoToast.error(message, { hideAfter: 6 });
      } else {
        cogoToast.success("Account updated");
      }
      return;
    },
    [accountActions.DELETE_ACCOUNT_SUCCESS]: (state, action) => {
      cogoToast.success("Account deleted");
      return;
    },
    [accountActions.DELETE_ACCOUNT_PROPERTY_SUCCESS]: (state, action) => {
      cogoToast.success("Property successfully deleted");
      return;
    },
    [combineActions(accountActions.GHOST_LOGIN_ERROR, accountActions.GET_ACCOUNTS_ERROR, accountActions.UPDATE_ACCOUNT_QUOTAS_ERROR, accountActions.UPDATE_ACCOUNT_SESSION_QUOTAS_BONUS_ERROR, accountActions.UPDATE_ACCOUNT_ERROR, accountActions.UPDATE_PLAN_ERROR, accountActions.DELETE_ACCOUNT_ERROR, accountActions.GET_ES_STATS_ERROR, accountActions.GET_ACCOUNT_LOGS_ERROR)]: (state, action) => {
      const errorMsg = action.payload.error.toString() || action.payload.error.message || action.payload.error.response.data.error.message;
      cogoToast.error(errorMsg);
      return update(state, { error: { $set: errorMsg } });
    },
  },
  defaultState
);

export default accountReducer;
