import { createActions } from "redux-actions";
import { createRequestAction } from "../utils";

const accountActions = {
  ...createActions({
    GET_ACCOUNTS: (query, sort, order, from, filterType, filterValue) => ({
      query,
      sort,
      order,
      from,
      filterType,
      filterValue,
    }),
    GET_ACCOUNT: (accountID) => ({ accountID }),
    GET_PLANS: () => ({}),
    GHOST_LOGIN: (login) => ({ login }),
    SEARCH_EMAIL: (email) => ({ email }),
    CURRENT_PAGE: (page) => ({ page }),
    UPDATE_ACCOUNT_QUOTAS: (data, id) => ({ data, id }),
    UPDATE_ACCOUNT_SESSION_QUOTAS_BONUS: (data, id) => ({ data, id }),
    UPDATE_ACCOUNT: (data, id) => ({ data, id }),
    DELETE_ACCOUNT: (id) => ({ id }),
    UPDATE_PLAN: (data, id) => ({ data, id }),
    UPDATE_AGENT: (data, accountID, agentID) => ({ data, accountID, agentID }),
    CLEAR_TOKEN: () => ({}),
    SET_FILTER_TYPE: (filterType) => ({ filterType }),
    SET_FILTER_VALUE: (filterValue) => ({ filterValue }),
    SET_SORTBY: (sort) => ({ sort }),
    SET_SORT_ORDER: (sortOrder) => ({ sortOrder }),
    UPDATE_EMAIL: (email) => ({ email }),
    GET_ES_STATS: (accountID) => ({ accountID }),
    GET_ACCOUNT_PROPERTIES: (accountID) => ({ accountID }),
    ADD_ACCOUNT_PROPERTY: (accountID, data) => ({ accountID, data }),
    DELETE_ACCOUNT_PROPERTY: (accountID, property) => ({ accountID, property }),
    GET_API_ERRORS: (accountID) => ({ accountID }),
    GET_ACCOUNT_LOGS: (accountID) => ({ accountID }),
  }),
  ...createRequestAction("GET_ACCOUNTS"),
  ...createRequestAction("GET_PLANS"),
  ...createRequestAction("GET_ACCOUNT"),
  ...createRequestAction("GHOST_LOGIN"),
  ...createRequestAction("UPDATE_ACCOUNT_QUOTAS"),
  ...createRequestAction("UPDATE_ACCOUNT_SESSION_QUOTAS_BONUS"),
  ...createRequestAction("UPDATE_ACCOUNT"),
  ...createRequestAction("DELETE_ACCOUNT"),
  ...createRequestAction("UPDATE_AGENT"),
  ...createRequestAction("UPDATE_PLAN"),
  ...createRequestAction("UPDATE_EMAIL"),
  ...createRequestAction("GET_ES_STATS"),
  ...createRequestAction("GET_ACCOUNT_PROPERTIES"),
  ...createRequestAction("ADD_ACCOUNT_PROPERTY"),
  ...createRequestAction("DELETE_ACCOUNT_PROPERTY"),
  ...createRequestAction("GET_API_ERRORS"),
  ...createRequestAction("GET_ACCOUNT_LOGS"),
  SEARCH_EMAIL: "SEARCH_EMAIL",
  CURRENT_PAGE: "CURRENT_PAGE",
  CLEAR_TOKEN: "CLEAR_TOKEN",
  SET_FILTER_TYPE: "SET_FILTER_TYPE",
  SET_FILTER_VALUE: "SET_FILTER_VALUE",
  SET_SORTBY: "SET_SORTBY",
  SET_SORT_ORDER: "SET_SORT_ORDER",
};

export default accountActions;
