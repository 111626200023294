import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { accountActions, accountSelectors } from "../../redux/accounts/index";
import { reduxLoaderSelectors } from "../../redux/redux-loader";
import { ExtendStyledTable } from "../tables/styles";
import { Box } from "./Info";
import DateParagraph from "../styled/DateParagraph";
import sortBy from "lodash/sortBy";
import TableOrderButton from "../styled/TableOrderButton";

const NamespaceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  & p {
    margin-right: 16px;
    margin-bottom: 0;
  }
  & select {
    min-width: 155px;
    outline: 0;
    padding: 2px 6px;
    border-radius: 4px;
  }
`;
const namespaceTranslation = {
  "apierrors:res-sync": "res-sync",
  "apierrors:record-api": "record-api",
};

const APIErrors = ({ accountID, apiErrors, getApiErrors, apiErrorsState: { isDone } }) => {
  const getNamespaces = () => {
    if (apiErrors && apiErrors.length > 0) {
      const namespaces = apiErrors.map((item) => item?.namespace);
      return ["-", ...new Set(namespaces)];
    }
    return ["-"];
  };

  const [order, setOrder] = useState("");
  const [currentNamespace, setNamespace] = useState("-");

  useEffect(() => {
    if (apiErrors === undefined) {
      getApiErrors(accountID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTableData = (data) => {
    if (!data || data.length <= 0) {
      return (
        <tr>
          <td>No data</td>
          <td>No data</td>
          <td>No data</td>
          <td>No data</td>
          <td>No data</td>
        </tr>
      );
    }

    const sorted = order === "" ? data : sortBy(data, (item) => (order === "DESC" ? -item.last_seen : item.last_seen));

    return sorted.map((apiErr) => {
      let param;
      let description;
      // backward compatibility
      try {
        const json = JSON.parse(apiErr.last_payload);
        param = json.param;
        description = json.description;
      } catch {
        description = apiErr.last_payload;
      }
      const isoDate = new Date(apiErr.last_seen * 1000).toISOString();
      return (
        <tr>
          <td>{namespaceTranslation[apiErr?.namespace]}</td>
          <td>{apiErr?.type}</td>
          <td>{apiErr?.count}</td>
          <td>{param}</td>
          <td>{description}</td>
          <td>
            <DateParagraph data={isoDate} />
          </td>
        </tr>
      );
    });
  };

  const namespaces = getNamespaces(0);

  const filterErrors = (data) => {
    if (currentNamespace === "-") return data;
    const filteredData = data.filter((item) => item?.namespace === currentNamespace);
    return filteredData;
  };

  return (
    <Box>
      <h2>API Errors</h2>
      <NamespaceContainer>
        <p>Namespace:</p>
        <select name="namespace" id="namespace" required onChange={(e) => setNamespace(e.target.value)} value={currentNamespace}>
          {namespaces.map((item) => (
            <option value={item}>{item === "-" ? item : namespaceTranslation[item]}</option>
          ))}
        </select>
      </NamespaceContainer>
      {apiErrors === undefined ? (
        <p>{isDone ? "Failed to fetch :(" : "Loading..."}</p>
      ) : (
        <ExtendStyledTable whiteSpace="normal" wordBreak="break-all">
          <thead>
            <tr>
              <th>Namespace</th>
              <th>Type</th>
              <th>Count</th>
              <th>Param</th>
              <th>Description</th>
              <th>
                Last Seen
                <TableOrderButton order={order} setOrder={setOrder} />
              </th>
            </tr>
          </thead>
          <tbody>{renderTableData(filterErrors(apiErrors))}</tbody>
        </ExtendStyledTable>
      )}
    </Box>
  );
};

export default connect(
  // mapStateToProps
  (state, ownProps) => (state) => {
    return {
      apiErrors: accountSelectors.getApiErrors(state),
      apiErrorsState: reduxLoaderSelectors.getLoadingState(state, accountActions.getApiErrors),
    };
  },
  // mapDispatchToProps
  (dispatch) => {
    return bindActionCreators(
      {
        getApiErrors: accountActions.getApiErrors,
      },
      dispatch
    );
  }
)(APIErrors);
