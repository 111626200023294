import { createActions } from "redux-actions";
import { createRequestAction } from "../utils";

const integrationsActions = {
  ...createActions({
    GET_INTEGRATIONS: () => ({}),
  }),
  ...createRequestAction("GET_INTEGRATIONS"),
};

export default integrationsActions;
