import React from "react";
import styled from "styled-components";
import { formatFromNow } from "../../utils/formatter";
import { parseDate } from "../../utils/date";
import { Progress } from "reactstrap";
import { MoreAccountInfo } from "./styles";

const ExtendMoreAccountInfo = styled(MoreAccountInfo)`
  align-items: ${(props) => (props.column ? "flex-start" : "center")};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  .progress {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 12px;
    height: 1.2rem;
    .progress-bar {
      color: #fff;
      padding: 2px 4px;
    }
  }
`;

const DataParagraph = styled.p`
  cursor: pointer;
  text-decoration: underline;
  & span:nth-of-type(2) {
    display: none;
  }
  &:hover {
    & span:nth-of-type(1) {
      display: none;
    }
    & span:nth-of-type(2) {
      display: block;
    }
  }
`;

const QuotasTable = ({ quotas }) => {
  delete quotas.account_id;
  const formatedDate = parseDate(quotas.quota_reset_date);
  const dataFromNow = formatFromNow(quotas.quota_reset_date);
  return (
    <>
      <ExtendMoreAccountInfo column>
        <Progress color="info" value={quotas.sessions_count} max={quotas.sessions_count_month}>{`${
          quotas.sessions_count || 0
        }/${quotas.sessions_count_month}`}</Progress>
      </ExtendMoreAccountInfo>
      <MoreAccountInfo>
        <p>
          <strong>Agents limit</strong>
        </p>
        <p>
          <span>{quotas.agents_limit}</span>
        </p>
      </MoreAccountInfo>
      <MoreAccountInfo>
        <p>
          <strong>Websites limit</strong>
        </p>
        <p>
          <span>{quotas.websites_limit}</span>
        </p>
      </MoreAccountInfo>
      <MoreAccountInfo>
        <p>
          <strong>Quota retention</strong>
        </p>
        <p>
          <span>{quotas.quota_retention} month</span>
        </p>
      </MoreAccountInfo>
      <MoreAccountInfo style={{ borderBottom: "none" }}>
        <p>
          <strong>Quota reset date</strong>
        </p>
        <DataParagraph>
          <span>{dataFromNow}</span>
          <span>{formatedDate}</span>
        </DataParagraph>
      </MoreAccountInfo>
    </>
  );
};

export default QuotasTable;
