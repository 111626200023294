import React, { useEffect, useState, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Alert } from "reactstrap";
import { auth, GoogleAuthProvider } from "../firebase";
import { userActions, userSelectors } from "../redux/user/index";
import { reduxLoaderSelectors } from "../redux/redux-loader";

const LoginView = ({ user, signedIn, getUserLoginState: { isLoading } }) => {
  const [redirect, setRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleLogin = useCallback(() => {
    auth
      .signInWithPopup(GoogleAuthProvider)
      .then((res) => {
        signedIn(res.user);
      })
      .then((res) => setRedirect(true))
      .catch((err) => {
        setErrorMessage(err.errorMessage);
        // accountActions.signOut();
      });
  }, [signedIn]);

  useEffect(() => {
    let timeout;
    if (user && user.email) {
      setRedirect(true);
    } else {
      timeout = setTimeout(() => handleLogin(), 2000);
    }
    return () => clearTimeout(timeout);
  }, [handleLogin, user]);

  return (
    <div className="container-fluid signin--container">
      <h1 className="signin--header">Welcome to LiveSession CRM</h1>
      <Button disabled={isLoading} onClick={() => handleLogin()}>
        Sign in
      </Button>
      {errorMessage && (
        <Alert style={{ marginTop: 20 }} color="danger">
          Can't log in. Check your credentials.
        </Alert>
      )}
      {user && user.email && (
        <Alert style={{ marginTop: 20 }} color="success">
          Logged in as {user.email}. Redirecting to dashboard.
        </Alert>
      )}
      {redirect && <Redirect to="/" />}
    </div>
  );
};

export default connect(
  // mapStateToProps
  (state, ownProps) => (state) => {
    return {
      user: userSelectors.getUser(state),
      getUserLoginState: reduxLoaderSelectors.getLoadingState(state, userActions.userSignIn),
    };
  },
  // mapDispatchToProps
  (dispatch) => {
    return bindActionCreators(
      {
        signedIn: userActions.userSignIn,
      },
      dispatch
    );
  }
)(LoginView);
