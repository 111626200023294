import React from "react";
import { Container, Row, Col } from "reactstrap";

import Navbar from "./navbar";

const Layout = (Wrapped) => {
  const LayoutWrapper = (props) => {
    return (
      <>
        <Navbar {...props} />
        <Container fluid>
          <Row>
            <Col colSpan={12}>
              <Wrapped {...props} />
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  return LayoutWrapper;
};

export default Layout;
