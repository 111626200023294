import React from "react";
import styled from "styled-components";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

export const StyledButton = styled.button`
  height: 100%;
  padding: 0 4px;
  margin-left: 8px;
  min-width: 23px;
  cursor: pointer;
  color: #fff;
  background-color: transparent;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid #fff;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 4px;
  &:focus {
    outline: 0;
  }
  & svg {
    transform: translateY(3px);
    font-size: 14px;
    padding: 0;
  }
`;

const TableOrderButton = ({ order, setOrder, onClick }) => {
  const sortValues = {
    DESC: <MdKeyboardArrowDown />,
    ASC: <MdKeyboardArrowUp />,
    "": "-",
  };

  const handleSortOrder = () => {
    const orders = ["DESC", "ASC", ""];
    const idx = orders.indexOf(order);
    const newOrder = orders[idx === 2 ? 0 : idx + 1];
    return newOrder;
  };

  const handleSortChange = (e) => {
    setOrder(handleSortOrder());
    if (onClick) {
      onClick();
    }
  };

  return <StyledButton onClick={handleSortChange}>{sortValues[order]}</StyledButton>;
};

export default TableOrderButton;
