import React from "react";
import styled from "styled-components";
import { formatFromNow } from "../../utils/formatter";
import { parseDate } from "../../utils/date";

const Paragraph = styled.p`
  cursor: pointer;
  font-size: ${({ size }) => size && `${size}px`} !important;
  margin: 0;
  & span:nth-of-type(2) {
    display: none;
  }
  &:hover {
    & span:nth-of-type(1) {
      display: ${(props) => (props.withSecondDate ? "none" : "block")};
    }
    & span:nth-of-type(2) {
      display: block;
    }
  }
  & span.green {
    color: ${(props) => props.color};
  }
`;

const DateParagraph = ({ data, size = 11, endDate = false }) => {
  const formatedDate = parseDate(data);
  const dataFromNow = formatFromNow(data);
  const checkEndDate = () => {
    if (endDate && dataFromNow.includes("ago")) return true;
  };
  return (
    <Paragraph size={size} withSecondDate={formatedDate !== dataFromNow} color={checkEndDate() ? "#fff" : "#62c462"}>
      {formatedDate !== dataFromNow && <span className="green">{dataFromNow}</span>}
      <span className={formatedDate !== dataFromNow ? "green" : null}>{formatedDate}</span>
    </Paragraph>
  );
};

export default DateParagraph;
