import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { accountActions } from "../../redux/accounts";
import cogoToast from "cogo-toast";

const TokensBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & a {
    margin: 0 4px;
    color: ${(props) => (props.toast ? "#000" : "#fff")};
  }
`;

const GeneratedTokens = ({ clearToken, ghostLogin, login, toast = false }) => {
  useEffect(() => {
    setTimeout(() => {
      clearToken();
    }, 7000);
    if (toast) {
      // eslint-disable-next-line no-unused-vars
      const { hide } = cogoToast
        .info(
          <>
            <p style={{ marginBottom: 4 }}>{login}</p>
            <TokensBox toast={toast}>
              <a
                onClick={() => clearToken()}
                href={`${process.env.REACT_APP_WEBAPP_URL}/token?token=${ghostLogin.token}&ghost=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Prod
              </a>
              {" | "}
              <a
                onClick={() => clearToken()}
                href={`https://app.staging.livesession.io/token?token=${ghostLogin.token}&ghost=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Staging
              </a>
              {" | "}
              <a
                onClick={() => clearToken()}
                href={`http://localhost:3000/token?token=${ghostLogin.token}&ghost=1`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Local
              </a>
              <a
                onClick={() => clearToken()}
                href={buildUrl('http://localhost:3000', true)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Local (session)
              </a>
            </TokensBox>
          </>,
          {
            hideAfter: 7,
          }
        )
        .then(() => clearToken());
    }
  });

  const buildUrl = (domain, sessionGhost) => {
    const url = new URL(`${domain}/token`)

    url.searchParams.append('token', ghostLogin.token)
    url.searchParams.append('ghost', '1')

    if (sessionGhost) {
      url.searchParams.append('session', '1')
    }

    return url.toString()
  }

  return (
    <>
      {!toast && (
        <>
          <p style={{ marginBottom: 4 }}>{login}</p>
          <div>
            <TokensBox toast={toast}>
                <a
                  onClick={() => clearToken()}
                  href={buildUrl(process.env.REACT_APP_WEBAPP_URL)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prod
                </a>
                {" | "}
                <a
                  onClick={() => clearToken()}
                  href={buildUrl('https://app.staging.livesession.io')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Staging
                </a>
                {" | "}
                <a
                  onClick={() => clearToken()}
                  href={buildUrl('http://localhost:3000')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Local
                </a>
            </TokensBox>
            <TokensBox toast={toast}>
              <a
                onClick={() => clearToken()}
                href={buildUrl('http://localhost:3000', true)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Local (session)
              </a>
            </TokensBox>
          </div>
        </>
      )}
    </>
  );
};

export default connect(null, (dispatch) => {
  return bindActionCreators(
    {
      clearToken: accountActions.clearToken,
    },
    dispatch
  );
})(GeneratedTokens);
