import { all, fork } from "redux-saga/effects";
import { createRequest } from "../utils";
import clustersActions from "./actions";
import api from "../../utils/api";

const getStatsRequest = createRequest(clustersActions.GET_STATS, api.get, (action) => {
  return { url: "/stats" };
});

const getClustersRequest = createRequest(clustersActions.GET_CLUSTERS, api.get, (action) => {
  return { url: "/stats/clusters" };
});

const getErrorTotalRequest = createRequest(clustersActions.GET_ERROR_TOTAL, api.get, (action) => {
    const {dataType, clusterHashID} = action.payload
    return { url: `/stats/not-synced?data_type=${dataType}&cluster_hash_id=${clusterHashID}`};
});

const getClusterAPIErrors = createRequest(clustersActions.GET_CLUSTER_API_ERRORS, api.get, (action) => {
    const {searchCluster, dataCluster} = action.payload
    return { url: `/stats/api-errors?search_cluster=${searchCluster}&data_cluster=${dataCluster}` };
});

export default function* rootSaga() {
  yield all([
      fork(getStatsRequest),
      fork(getClustersRequest),
      fork(getErrorTotalRequest),
      fork(getClusterAPIErrors),
  ]);
}
