import React, { useState } from "react";
import styled from "styled-components";
import { ModalHeader, ModalBody, Modal } from "reactstrap";
import StyledButton from "../styled/StyledButton";
import { connect } from "react-redux";
import { accountActions } from "../../redux/accounts";
import { bindActionCreators } from "redux";
import * as Styled from "../styled/FormStyles";
import { reduxLoaderSelectors } from "../../redux/redux-loader";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledModal = styled(Modal)`
  max-width: 600px;
  .modal-header {
    padding: 1rem 2rem;
  }
  button.close span {
    color: red;
    text-shadow: none;
    font-size: 35px;
  }
  .modal-title {
    line-height: 38px;
  }
  .modal-body {
    padding: 2rem 1rem;
  }
`;

const Input = styled.input`
  box-shadow: 1px 2px 10px -2px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 4px 8px;
  border: none;
  line-height: 28px;
  height: 28px;
  min-width: 300px;
  outline: 0;
`;

const QuotasForm = ({
  agentStatus,
  agentRole,
  agentID,
  login,
  isModalAgentOpen,
  setOpenAgentModal,
  updateAgent,
  accountID,
  getUpdateAgent: { isDone, isLoading, isError },
}) => {
  const [role, setRole] = useState(agentRole);
  const [status, setStatus] = useState(agentStatus);
  const [inputValue, setInputvalue] = useState(login || "");

  const addData = async (e) => {
    e.preventDefault();
    try {
      const JSONData = JSON.stringify({
        role,
        status,
        login: inputValue === login ? "" : inputValue,
      });
      await updateAgent(JSONData, accountID, agentID);
    } catch (e) {
      if (isError) console.error(e);
    } finally {
      if (isDone && !isError) setOpenAgentModal("");
    }
  };
  return (
    <StyledModal
      isOpen={login === isModalAgentOpen}
      toggle={() => setOpenAgentModal("")}
      modalTransition={{ timeout: 20 }}
      backdropTransition={{ timeout: 20 }}
    >
      <ModalHeader toggle={() => setOpenAgentModal("")}>Update agent</ModalHeader>
      <ModalBody>
        <StyledForm onSubmit={(e) => addData(e)}>
          <Styled.LabelSelect htmlFor="status" style={{ transform: "translateX(-90px)" }}>
            <span>Status:</span>
            <select
              name="status"
              id="status"
              required
              onChange={(e) => setStatus(e.target.value)}
              defaultValue={status}
              style={{ minWidth: 300 }}
            >
              <option value="active">Active</option>
              <option value="not_active">Not active</option>
              <option value="blocked">Blocked</option>
              <option value="deleted">Deleted</option>
            </select>
          </Styled.LabelSelect>
          <Styled.LabelSelect htmlFor="role" style={{ transform: "translateX(-90px)" }}>
            <span>Role:</span>
            <select
              name="role"
              id="role"
              required
              onChange={(e) => setRole(e.target.value)}
              defaultValue={role}
              style={{ minWidth: 300 }}
            >
              <option value="agent">Agent</option>
              <option value="admin">Admin</option>
              <option value="owner">Owner</option>
            </select>
          </Styled.LabelSelect>
          <Styled.LabelSelect htmlFor="email" style={{ transform: "translateX(-90px)" }}>
            <span>Email:</span>
            <Input type="email" name="email" onChange={(e) => setInputvalue(e.target.value)} value={inputValue} />
          </Styled.LabelSelect>
          <StyledButton
            loading
            isLoading={isLoading}
            as="button"
            secondary
            type="submit"
            style={{ margin: "35px auto 0", border: "none" }}
          >
            Update
          </StyledButton>
        </StyledForm>
      </ModalBody>
    </StyledModal>
  );
};

export default connect(
  (state) => (state) => {
    return {
      getUpdateAgent: reduxLoaderSelectors.getLoadingState(state, accountActions.updateAgent),
    };
  },
  (dispatch) => {
    return bindActionCreators(
      {
        updateAgent: accountActions.updateAgent,
      },
      dispatch
    );
  }
)(QuotasForm);
