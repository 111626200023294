import React from "react";
import { formatData } from "../../utils/formatter";
import { MoreAccountInfo } from "./styles";

const StatisticsTable = ({ statistics }) => {
  delete statistics.account_id;
  return (
    <>
      {" "}
      {Object.entries(statistics).map((obj, i) => {
        return (
          <MoreAccountInfo key={i}>
            <p>
              <strong>{obj[0].charAt(0).toUpperCase() + obj[0].slice(1).replace(/_/g, " ")}</strong>
            </p>
            {obj[0] === "country_code" ? (
              <img
                src={`${process.env.PUBLIC_URL}/images/${statistics?.country_code?.toLowerCase()}.svg`}
                alt="flag"
                style={{ width: 15, height: "auto" }}
              />
            ) : obj[0] === "referrer" && obj[1].includes("https" || "http") ? (
              <a href={obj[1]} target="_blank" rel="noreferrer noopener">
                {obj[1]}
              </a>
            ) : (
              <p>{formatData(obj[1], true)}</p>
            )}
          </MoreAccountInfo>
        );
      })}
    </>
  );
};

export default StatisticsTable;
