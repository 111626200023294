import styled from "styled-components";
import { Table, Modal } from "reactstrap";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & label {
    width: 70%;
    margin: 15px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & label input {
    padding: 10px;
    flex-basis: 65%;
  }
  & label select {
    width: 80%;
    flex-basis: 65%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledModal = styled(Modal)`
  .modal-header {
    padding: 1rem 2rem;
  }
  button.close span {
    color: red;
    text-shadow: none;
    font-size: 35px;
  }
  .modal-title {
    line-height: 38px;
  }
  .modal-body {
    padding: 2rem 1rem;
  }
`;

export const StyledTable = styled(Table)`
  &.table {
    width: 100%;
    margin: 0 auto 20px;
    color: #fff;
    background-color: #302f35;
    max-width: 1600px;
    border-color: transparent;
    border-spacing: 0;
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
    & .progress {
      margin: 0px !important;
    }
  }
  thead {
    margin-bottom: 2px;
    border-bottom: 1px solid #19191d;
    & tr {
      border-bottom: 2px solid #19191d;
      & th {
        border: none !important;
        background-color: rgb(37, 36, 41);
        padding: 10px 12px !important;
        &:first-child {
          border-radius: 8px 0 0 0;
        }
        &:last-child {
          border-radius: 0 8px 0 0;
        }
      }
    }
  }
  tbody {
    border-radius: 8px;
  }
  tbody tr:last-child {
    border-bottom: none;
  }
  tr {
    border-bottom: 1px solid #19191d;
    td {
      padding: 8px 12px !important;
      text-align: left;
      vertical-align: middle;
      font-family: "Muli", sans-serif;
      font-size: 12px;
      border-top: none;
      height: 36px;
    }
    th {
      border-top: none;
      padding: 8px 12px !important;
      text-align: left;
      vertical-align: middle;
      font-family: "Muli", sans-serif;
      font-size: 14px;
      height: 36px;
      & .btn {
        height: fit-content;
        padding: 0 4px;
        margin-left: 8px;
        font-size: 12px;
        min-width: 40px;
        & svg {
          transform: translateY(3px);
          font-size: 14px;
          padding: 0;
        }
      }
    }
  }
  .progress {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 12px;
    height: 1.2rem;
    .progress-bar {
      color: #fff;
      padding: 2px 4px;
    }
  }
`;
