import React, { useState } from "react";
import { StyledModal, StyledForm } from "./UpdatePlan";
import { ModalHeader, ModalBody } from "reactstrap";
import * as Styled from "../styled/FormStyles";
import StyledButton from "../styled/StyledButton";
import { connect } from "react-redux";
import { accountActions } from "../../redux/accounts";
import { bindActionCreators } from "redux";
import { reduxLoaderSelectors } from "../../redux/redux-loader";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { CustomTippy } from "../tables/styles";

const types = ["string", "int", "bool"];

const AddProperty = ({
  accountID,
  setOpen,
  isOpen,
  addAccountPropertyState: { isLoading, isDone, isError },
  addAccountProperty,
}) => {
  const [name, setName] = useState("");
  const [type, setType] = useState("string");
  const [val, setVal] = useState("");
  const [isPublic, setIsPublic] = useState(false);

  const addProperty = async (e) => {
    e.preventDefault();
    try {
      const requestBody = JSON.stringify({
        type,
        name,
        value: val,
        public: isPublic,
      });
      await addAccountProperty(accountID, requestBody);
    } catch (e) {
      console.error(e);
    } finally {
      if (isDone && !isError) {
        setOpen(false);
      }
    }
  };

  return (
    <StyledModal
      isOpen={isOpen}
      toggle={() => setOpen(!isOpen)}
      modalTransition={{ timeout: 20 }}
      backdropTransition={{ timeout: 20 }}
    >
      <ModalHeader toggle={() => setOpen(!isOpen)}>Add account property</ModalHeader>
      <ModalBody>
        <StyledForm onSubmit={addProperty}>
          <Styled.LabelSelect htmlFor="property-type">
            <span style={{ alignSelf: "flex-start", marginTop: 3 }}>Type:</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <select
                name="property-type"
                id="property-type"
                defaultValue={type}
                onChange={(e) => setType(e.target.value)}
                required
              >
                {types.map((plan) => (
                  <option key={plan} value={plan}>
                    {plan}
                  </option>
                ))}
              </select>
            </div>
          </Styled.LabelSelect>
          <Styled.LabelInput htmlFor="property-name">
            <span>Name:</span>
            <input id="property-name" type="text" value={name} onChange={(e) => setName(e.target.value)} required />
          </Styled.LabelInput>
          <Styled.LabelInput htmlFor="property-value">
            <span>Value:</span>
            <input id="property-value" type="text" value={val} onChange={(e) => setVal(e.target.value)} />
          </Styled.LabelInput>
          <Styled.UpdateLabel htmlFor="property-public">
            <div />
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                name="property-public"
                id="property-public"
                value={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
              />
              <span>Public</span>
              <CustomTippy content="Property will be send to frontend if value is true">
                <div style={{ display: "inline", marginLeft: 8, lineHeight: "100%" }}>
                  <BsFillInfoCircleFill />
                </div>
              </CustomTippy>
            </div>
          </Styled.UpdateLabel>
          <StyledButton
            isLoading={isLoading}
            loading
            as="button"
            secondary
            type="submit"
            style={{ margin: "35px auto 0", border: "none" }}
          >
            Add
          </StyledButton>
        </StyledForm>
      </ModalBody>
    </StyledModal>
  );
};

export default connect(
  (state) => (state) => {
    return {
      addAccountPropertyState: reduxLoaderSelectors.getLoadingState(state, accountActions.addAccountProperty),
    };
  },
  (dispatch) => {
    return bindActionCreators(
      {
        addAccountProperty: accountActions.addAccountProperty,
      },
      dispatch
    );
  }
)(AddProperty);
