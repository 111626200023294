import React, { useState } from "react";
import {
  EsData,
  EsDataHeader,
  EsDataSync,
  EsDataLeased,
  EsSessions,
  EsEvents,
  StyledClusterErrors,
  ExtendDropdownList,
  CustomTable,
} from "../styled/ClustersStyles";
import { Line } from "react-chartjs-2";
import _ from "lodash";
import { Alert, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { MdMoreVert } from "react-icons/md";
import ClustersErrorModal from "../modals/ClustersError";
import TableOrderButton from "../styled/TableOrderButton";
import { formatFromNow } from "../../utils/formatter";

const SingleCluster = ({
  clusterData,
  clusterErrors = [],
  clusterTitle,
  errorTotal,
  time,
  onErrorTotalClick,
  refreshDates,
}) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [order, setOrder] = useState({
    sessions: "",
    pageviews: "",
    events: "",
  });
  const [orderSeen, setOrderSeen] = useState({
    sessions: "",
    pageviews: "",
    events: "",
  });
  const [modalData, setModalData] = useState(false);

  const clusterDataMap = clusterData.reduce((acc, d) => {
    const v = Object.keys(d).reduce(
      (acc, key) => ({
        ...acc,
        [key]: d[key],
      }),
      {}
    );

    return {
      ...acc,
      ...v,
    };
  }, {});

  function onErrorCheckClick(dataType) {
    return function (e) {
      e.preventDefault();
      onErrorTotalClick(dataType, clusterDataMap["cfg.hashID"]);
    };
  }

  const renderValue = (title) => _.flatten(Object.values(clusterDataMap[title])).pop();

  function getErrorTotal(dataType, withPercent = false) {
    const key = clusterDataMap["cfg.hashID"] + "." + dataType;
    if (withPercent) {
      return (
        <>
          {errorTotal[key]}{" "}
          <span className="danger-synced">({Math.floor((errorTotal[key] * 100) / renderValue(withPercent))}%)</span>
        </>
      );
    }
    return errorTotal[key];
  }

  const renderSyncedValue = (arg, synced, failed = false) => {
    const arg1 = renderValue(arg);
    const syncedValue = renderValue(synced);
    const result = arg1 / syncedValue;
    if (syncedValue === 0) return;
    if (failed) {
      const percent = Math.floor((syncedValue * 100) / arg1);
      return result !== 1 ? <span className="warning-synced">({100 - percent}%)</span> : null;
    }
    if (result === 1) {
      return <span className="success-synced">(100%)</span>;
    }
    return <span className="warning-synced">({Math.floor((syncedValue * 100) / arg1)}%)</span>;
  };

  const renderClusterErrors = (arg, id) => {
    if (arg) {
      let sorted;
      if (order[id] !== "") {
        sorted = _.sortBy(arg, (item) => (order[id] === "DESC" ? -item.count : item.count));
      } else if (orderSeen[id] !== "") {
        sorted = _.sortBy(arg, (item) => (orderSeen[id] === "DESC" ? -item.last_seen : item.last_seen));
      } else {
        sorted = arg;
      }
      return sorted.map((apiErr) => (
        <tr key={apiErr.group_id} onClick={() => setModalData(apiErr)}>
          <td style={{ color: "rgb(231, 75, 92)" }}>{apiErr.type}</td>
          <td style={{ color: "rgb(231, 75, 92)" }}>{apiErr.count}</td>
          <td style={{ color: "rgb(231, 75, 92)", textAlign: "right" }}>{formatFromNow(apiErr.last_seen * 1000)}</td>
        </tr>
      ));
    }
  };

  const modifyOrderState = (i, arg) =>
    setOrder((prevData) => ({
      ...prevData,
      [i]: arg || "",
    }));

  const modifySeenOrderState = (i, arg) =>
    setOrderSeen((prevData) => ({
      ...prevData,
      [i]: arg || "",
    }));

  const renderChart = (data1, data2, data3, data4) => {
    const chartData = {
      labels: refreshDates,
      datasets: [
        {
          label: data1[0].split(".").pop(),
          fill: false,
          tension: 0.1,
          backgroundColor: "rgba(15,222,92,1)",
          borderColor: "rgba(15,222,92,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(15,222,92,1)",
          pointBackgroundColor: "rgba(15,222,92,1)",
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(15,222,92,1)",
          pointHoverBorderColor: "rgba(15,222,92,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: data1[1],
          borderWidth: 2,
        },
        {
          label: data2[0].split(".").pop(),
          fill: false,
          tension: 0.1,
          backgroundColor: "orange",
          borderColor: "orange",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "orange",
          pointBackgroundColor: "orange",
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "orange",
          pointHoverBorderColor: "orange",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: data2[1],
          borderWidth: 2,
        },
        {
          label: data3[0].split(".").pop(),
          fill: false,
          tension: 0.1,
          backgroundColor: "rgb(231, 75, 92)",
          borderColor: "rgb(231, 75, 92)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(231, 75, 92)",
          pointBackgroundColor: "rgb(231, 75, 92)",
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(231, 75, 92)",
          pointHoverBorderColor: "rgb(231, 75, 92)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: data3[1],
          borderWidth: 2,
        },
        {
          label: data4[0].split(".").pop(),
          fill: false,
          tension: 0.1,
          backgroundColor: "rgba(75,152,192,1)",
          borderColor: "rgba(75,152,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,152,192,1)",
          pointBackgroundColor: "rgba(75,152,192,1)",
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,152,192,1)",
          pointHoverBorderColor: "rgba(75,152,192,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: data4[1],
          borderWidth: 2,
        },
      ],
    };
    return chartData;
  };
  return (
    <>
      <EsData>
        <EsDataHeader>
          <Alert color="dark">
            <b>{clusterTitle}</b>
          </Alert>
          <Alert color="dark">{clusterDataMap["cfg.hashID"]}</Alert>
          <Alert color="dark">{time}</Alert>
          <ExtendDropdownList size="sm" isOpen={isListOpen} toggle={() => setIsListOpen(!isListOpen)}>
            <DropdownToggle>
              <MdMoreVert />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>Resync events</DropdownItem>
            </DropdownMenu>
          </ExtendDropdownList>
        </EsDataHeader>
        <EsDataSync>
          <Alert color={renderValue("cfg.syncable") ? "success" : "danger"}>Syncable</Alert>
          <Alert color={renderValue("cfg.writable") ? "success" : "danger"}>Writable</Alert>
          <Alert color={renderValue("cfg.writableMetadata") ? "success" : "danger"}>Metadata</Alert>
        </EsDataSync>
        <EsDataLeased>
          <p>
            Leased by:{" "}
            <a
              href={`https://console.cloud.google.com/kubernetes/pod/us-central1-c/k8s-cluster1/default/${clusterDataMap["lease.owner"]}/details`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>{Object.values(clusterDataMap["lease.owner"])}</b>
            </a>
          </p>
        </EsDataLeased>
        <div>
          <Line
            data={() =>
              renderChart(
                ["stats.session", clusterDataMap["stats.sessions"]],
                ["stats.session.failed", clusterDataMap["stats.sessions.failed"]],
                ["stats.session.syncError", clusterDataMap["stats.sessions.syncError"]],
                ["stats.session.markedAsSynced", clusterDataMap["stats.sessions.markedAsSynced"]]
              )
            }
            height={170}
            width={400}
          />
        </div>
        <EsSessions>
          <div>
            <p>sessions</p> <p>{renderValue("stats.sessions")}</p>
          </div>
          <div>
            <p>sessions.synced</p>{" "}
            <p>
              {renderValue("stats.sessions.markedAsSynced")}{" "}
              {renderSyncedValue("stats.sessions", "stats.sessions.markedAsSynced")}
            </p>
          </div>
          <div>
            <p>sessions.failed</p>{" "}
            <p className="danger">
              {renderValue("stats.sessions.failed")}{" "}
              {renderSyncedValue("stats.sessions", "stats.sessions.markedAsSynced", true)}
            </p>
          </div>
          <div>
            <p>sessions.error</p> <p className="danger">{renderValue("stats.sessions.syncError")}</p>
          </div>

          <div>
            <p>sessions.error.total</p>
            <p className="inherit-a">
              <button onClick={onErrorCheckClick("sessions")}>
                {typeof getErrorTotal("sessions") === "number" ? getErrorTotal("sessions") : "check"}
              </button>
            </p>
          </div>
        </EsSessions>
        <StyledClusterErrors>
          {clusterErrors?.sessions && clusterErrors?.sessions.length > 0 && (
            <CustomTable style={{ marginBottom: 16 }}>
              <thead>
                <tr>
                  <th>Error</th>
                  <th>
                    Count{" "}
                    <TableOrderButton
                      order={order.sessions}
                      setOrder={(arg) => modifyOrderState("sessions", arg)}
                      onClick={() => modifySeenOrderState("sessions")}
                    />
                  </th>
                  <th>
                    Last seen{" "}
                    <TableOrderButton
                      order={orderSeen.sessions}
                      setOrder={(arg) => modifySeenOrderState("sessions", arg)}
                      onClick={() => modifyOrderState("sessions")}
                    />
                  </th>
                </tr>
              </thead>{" "}
              <tbody>{renderClusterErrors(clusterErrors?.sessions, "sessions")}</tbody>
            </CustomTable>
          )}
        </StyledClusterErrors>
        <div>
          <Line
            data={() =>
              renderChart(
                ["stats.pageViews", clusterDataMap["stats.pageViews"]],
                ["stats.pageViews.failed", clusterDataMap["stats.pageViews.failed"]],
                ["stats.pageViews.syncError", clusterDataMap["stats.pageViews.syncError"]],
                ["stats.pageViews.markedAsSynced", clusterDataMap["stats.pageViews.markedAsSynced"]]
              )
            }
            height={170}
            width={400}
          />
        </div>
        <EsSessions>
          <div>
            <p>pageViews</p> <p>{renderValue("stats.pageViews")}</p>
          </div>
          <div>
            <p>pageViews.synced</p>{" "}
            <p>
              {renderValue("stats.pageViews.markedAsSynced")}{" "}
              {renderSyncedValue("stats.pageViews", "stats.pageViews.markedAsSynced")}
            </p>
          </div>
          <div>
            <p>pageViews.failed</p>{" "}
            <p className="danger">
              {renderValue("stats.pageViews.failed")}{" "}
              {renderSyncedValue("stats.pageViews", "stats.pageViews.markedAsSynced", true)}
            </p>
          </div>
          <div>
            <p>pageViews.error</p> <p className="danger">{renderValue("stats.pageViews.syncError")}</p>
          </div>
          <div>
            <p>pageViews.error.total</p>{" "}
            <p className="inherit-a">
              <button onClick={onErrorCheckClick("pageViews")}>
                {typeof getErrorTotal("pageViews") === "number" ? getErrorTotal("pageViews") : "check"}
              </button>
            </p>
          </div>
        </EsSessions>
        <StyledClusterErrors>
          {clusterErrors?.pageViews && clusterErrors?.pageViews.length > 0 && (
            <CustomTable style={{ marginBottom: 16 }}>
              <thead>
                <tr>
                  <th>Error</th>
                  <th>
                    Count{" "}
                    <TableOrderButton
                      order={order.pageviews}
                      setOrder={(arg) => modifyOrderState("pageviews", arg)}
                      onClick={() => modifySeenOrderState("pageviews")}
                    />
                  </th>
                  <th>
                    Last seen{" "}
                    <TableOrderButton
                      order={orderSeen.pageviews}
                      setOrder={(arg) => modifySeenOrderState("pageviews", arg)}
                      onClick={() => modifyOrderState("pageviews")}
                    />
                  </th>
                </tr>
              </thead>{" "}
              <tbody>{renderClusterErrors(clusterErrors?.pageViews, "pageviews")}</tbody>
            </CustomTable>
          )}
        </StyledClusterErrors>
        <div>
          <Line
            data={() =>
              renderChart(
                ["stats.events", clusterDataMap["stats.events"]],
                ["stats.events.failed", clusterDataMap["stats.events.failed"]],
                ["stats.events.syncError", clusterDataMap["stats.events.syncError"]],
                ["stats.events.markedAsSynced", clusterDataMap["stats.events.markedAsSynced"]]
              )
            }
            height={170}
            width={400}
          />
        </div>
        <EsEvents>
          <div>
            <p>events</p> <p>{renderValue("stats.events")}</p>
          </div>
          <div>
            <p>events.synced</p>{" "}
            <p>
              {renderValue("stats.events.markedAsSynced")}{" "}
              {renderSyncedValue("stats.events", "stats.events.markedAsSynced")}
            </p>
          </div>
          <div>
            <p>events.failed</p>{" "}
            <p className="danger">
              {renderValue("stats.events.failed")}{" "}
              {renderSyncedValue("stats.events", "stats.events.markedAsSynced", true)}
            </p>
          </div>
          <div>
            <p>events.error</p> <p className="danger">{renderValue("stats.events.syncError")}</p>
          </div>
          <div>
            <p>events.total</p> <p>{renderValue("stats.events.total")}</p>
          </div>
          <div>
            <p>events.error.total</p>{" "}
            <p className="inherit-a">
              <button onClick={onErrorCheckClick("events")}>
                {typeof getErrorTotal("events") === "number" ? getErrorTotal("events", "stats.events.total") : "check"}
              </button>
            </p>
          </div>
        </EsEvents>
        <StyledClusterErrors>
          {clusterErrors?.events && clusterErrors?.events.length > 0 && (
            <CustomTable style={{ margin: 0 }}>
              <thead>
                <tr>
                  <th>Error</th>
                  <th>
                    Count{" "}
                    <TableOrderButton
                      order={order.events}
                      setOrder={(arg) => modifyOrderState("events", arg)}
                      onClick={() => modifySeenOrderState("events")}
                    />
                  </th>
                  <th>
                    Last seen{" "}
                    <TableOrderButton
                      order={orderSeen.events}
                      setOrder={(arg) => modifySeenOrderState("events", arg)}
                      onClick={() => modifyOrderState("events")}
                    />
                  </th>
                </tr>
              </thead>{" "}
              <tbody>{renderClusterErrors(clusterErrors?.events, "events")}</tbody>
            </CustomTable>
          )}
        </StyledClusterErrors>
      </EsData>
      <ClustersErrorModal setOpen={setModalData} modalData={modalData} />
    </>
  );
};

export default SingleCluster;
