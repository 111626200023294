import { createActions } from "redux-actions";
import { createRequestAction } from "../utils";

const blacklistActions = {
  ...createActions({
    GET_BLACKLIST: blacklist => ({ blacklist }),
    DELETE_BLACKLIST_ITEM: id => ({ id }),
    POST_BLACKLIST_ITEM: data => ({ data })
  }),
  ...createRequestAction("GET_BLACKLIST"),
  ...createRequestAction("DELETE_BLACKLIST_ITEM"),
  ...createRequestAction("POST_BLACKLIST_ITEM")
};

export default blacklistActions;
