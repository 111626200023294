import { auth } from "../../firebase";
import { put, all, call } from "redux-saga/effects";
import userActions from "./actions";

const verifyUser = () => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(user => {
      if (user) {
        resolve(user);
      } else {
        reject(Error("It broke"));
      }
    });
  });
};

function* verifyAuth() {
  try {
    const user = yield call(verifyUser);
    yield put(userActions.userSignIn(user));
  } catch (e) {
    const { message } = e;
    yield put({ type: "USER_SIGN_OUT", message });
  }
}

export default function* rootSaga() {
  yield all([verifyAuth()]);
}
