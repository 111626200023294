import styled from "styled-components";
import { Dropdown } from "reactstrap";

export const StyledLabel = styled.label`
  border-radius: 20px;
  min-width: 400px;
  padding: 0 4px 0 14px;
  background-color: #252529;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin-bottom: 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  & svg {
    font-size: 18px;
  }
`;

export const StyledInputSearch = styled.input`
  margin-left: 12px;
  background-color: transparent;
  border: none;
  color: #fff;
  padding: 10px 0;
  &:focus {
    outline: 0;
  }
`;

export const ToolbarBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & p {
    margin-right: 24px;
    margin-bottom: 0;
    font-size: 18px;
    &:first-child {
      margin-right: 16px;
      padding-right: 16px;
      border-right: 1px solid #aaa;
    }
  }
`;

export const FiltersDropdown = styled(Dropdown)`
  &.dropdown {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  & .dropdown-menu {
    border: none;
    border-radius: 4px;
    background-color: #252529;
    top: 8px !important;
    min-width: 330px;
    margin-left: 4px;
    & .dropdown-item {
      padding: 0;
      & p {
        margin: 0;
        border: none;
        font-size: 14px;
      }
      & .filter-types {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        & label {
          margin-bottom: 0;
          cursor: pointer;
          width: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          input {
            margin-right: 4px;
            cursor: pointer;
          }
        }
      }
      &:focus {
        outline: 0;
      }
      &:hover {
        background-color: transparent;
      }
    }
    & h6.dropdown-header {
      padding-bottom: 16px;
    }
    & div.dropdown-header {
      font-size: 14px;
      display: flex;
      align-items: center;
      position: relative;
      padding: 0.5rem 1.5rem;
      color: #fff;
      padding-top: 24px;
      padding-bottom: 8px;
      &:hover {
        background-color: transparent;
      }
      &:focus {
        outline: 0;
      }
      & p {
        margin: 0;
        border: none;
        font-size: 14px;
      }
      & .trial,
      &.save {
        margin-bottom: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: auto;
        cursor: pointer;
        margin-top: 0;
        & #plan {
          margin-left: 8px;
        }
      }
      &.save {
        padding-top: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        & div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  & .btn.btn-secondary {
    background-color: #35363c;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #fff;
    padding: 0 12px;
    height: calc(100% - 8px);
    outline: 0;
    font-size: 14px;
    & svg {
      margin-left: 4px;
    }
  }
`;
export const ResetButton = styled.button`
  width: fit-content;
  max-width: 100%;
  padding: 6px 4px;
  background-color: transparent;
  border: none;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;
export const ClearQueryButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: 0;
  cursor: pointer;
  & svg {
    fill: #fff;
  }
`;

export const PartnerLabel = styled.label`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  & input {
    background-color: #3a3f44;
    border: none;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    &:focus {
      outline: 0;
    }
  }
`;
