import React from "react";
import styled from "styled-components";
import { ModalHeader, ModalBody, Modal } from "reactstrap";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";

const StyledModal = styled(Modal)`
  &.modal-dialog {
    max-width: 1100px;
  }
  .modal-header {
    padding: 1rem 2rem;
  }
  button.close span {
    color: red;
    text-shadow: none;
    font-size: 35px;
  }
  .modal-title {
    line-height: 38px;
  }
  .modal-body {
    padding: 2rem 1rem;
  }
`;

const DataContainer = styled.div`
  & .__json-pretty__ .__json-string__ {
    white-space: initial;
  }
  & .__json-pretty__ {
    padding: 8px 16px;
    overflow-wrap: break-word;
    white-space: pre-line;
  }
`;

const ClustersErrorModal = ({ modalData, setOpen }) => {
  return (
    <StyledModal
      isOpen={modalData}
      toggle={() => setOpen(false)}
      modalTransition={{ timeout: 20 }}
      backdropTransition={{ timeout: 20 }}
    >
      <ModalHeader toggle={() => setOpen(false)}>Error: {modalData?.type}</ModalHeader>
      <ModalBody>
        <DataContainer>{modalData && <JSONPretty id="json-pretty" data={modalData}></JSONPretty>}</DataContainer>
      </ModalBody>
    </StyledModal>
  );
};

export default ClustersErrorModal;
