import createCachedSelector from "re-reselect";

const defaultState = {
  isLoading: false,
  isDone: false,
  isError: false,
};

const getLoadingStateSelector = (state, action, id) => {
  // eslint-disable-next-line
  let name = action;
  if (typeof action === "function") {
    name = action.toString();
  }
  const actionName = id ? `${action}_${id}` : action;
  const loadingState = state.reduxLoader[actionName];
  if (!loadingState) {
    return defaultState;
  }
  return loadingState;
};

const getLoadingState = createCachedSelector(
  getLoadingStateSelector,
  (res) => res
)((state, action) => {
  return typeof action === "function" ? action.toString() : action;
});

export default {
  getLoadingState,
};
