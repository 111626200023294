import { createActions } from "redux-actions";

const reduxLoaderActions = {
  ...createActions({
    RESET_REDUX_LOADER_STATE: (action, id) => ({ action, id }),
    "@@redux-loader/LOADING": (action) => ({ action }),
    "@@redux-loader/ERROR": (action, error) => ({ action }),
    "@@redux-loader/LOADED": (action) => ({ action }),
  }),

  RESET_REDUX_LOADER_STATE: "RESET_REDUX_LOADER_STATE",
};

export default reduxLoaderActions;
