const getClusters = (state) => {
  return state.clusters.data;
};

const getClustersTime = (state) => {
  return state.clusters.time;
};

const getBaseCluster = (state) => {
  return state.clusters.baseCluster;
};

const getClustersErrors = (state) => {
  return state.clusters.error;
};

const getErrorTotal = (state) => {
  return state.clusters.errorTotal;
};

const getClusterApiErrors = (state) => {
  return state.clusters.clusterApiErrors;
};

export default { getClusters, getClustersErrors, getClustersTime, getBaseCluster, getErrorTotal, getClusterApiErrors };
