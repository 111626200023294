import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { userSelectors } from "../../redux/user";
import Loading from "../styled/StyledLoading";

const WithAuthentication = (Wrapped) => {
  const Authentication = (props) => {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
      const loaderTimeout = setTimeout(() => {
        setLoading(false);
      }, 3000);
      return () => clearTimeout(loaderTimeout);
    });

    const checkEmail = (user) => {
      return user.email.split("@")[1] === "livesession.io";
    };

    const renderView = () => {
      const { user } = props;
      if (user) {
        return checkEmail(user) ? <Wrapped {...props} /> : <div>You don't have access to this page.</div>;
      } else if (isLoading) {
        return <Loading />;
      } else {
        return <Redirect to="/signin" />;
      }
    };
    return <>{renderView()}</>;
  };

  const mapStateToProps = (state) => {
    return {
      user: userSelectors.getUser(state),
    };
  };

  return connect(mapStateToProps)(Authentication);
};

export default WithAuthentication;
