import { takeEvery, put } from "redux-saga/effects";

const createRequestSagaGenerator = (action, call, fn, requestID) => {
  return function*() {
    yield takeEvery(action, function*(payload) {
      try {
        const { body, url } = fn(payload);
        yield put({ type: `@@redux-loader/LOADING`, payload: { action: action, id: requestID } });
        const response = yield call(url, body);
        yield put({ type: `${action}_SUCCESS`, payload: { ...response, id: requestID } });
        yield put({ type: `@@redux-loader/LOADED`, payload: { action: action, id: requestID } });
      } catch (e) {

        yield put({ type: `${action}_ERROR`, payload: { error: e, id: requestID } });
        yield put({ type: `@@redux-loader/ERROR`, payload: { action: action, id: requestID } });
      }
      yield put({ type: `${action}_DONE` });
    });
  };
};

const createRequest = (action, call, fn, requestID) => {
  return createRequestSagaGenerator(action, call, fn, requestID);
};

const createRequestAction = (name) => {
  return {
    [name]: name,
    [`${name}_SUCCESS`]: `${name}_SUCCESS`,
    [`${name}_ERROR`]: `${name}_ERROR`,
    [`${name}_DONE`]: `${name}_DONE`,
  };
};

export { createRequestAction, createRequestSagaGenerator, createRequest };
