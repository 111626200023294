import styled from "styled-components";

export const Label = styled.label`
  margin: 0 0 12px 0;
  display: table;
  cursor: pointer;
`;

export const LabelSelect = styled.label`
  width: 100%;
  margin: 16px auto 0;
  display: grid;
  grid-template-columns: 140px 200px;
  justify-content: center;
  align-items: center;
  transform: translateX(-35px);
  & span {
    margin-right: 12px;
    text-align: right;
  }
  & select {
    box-shadow: 1px 2px 10px -2px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    padding: 4px 6px;
    border: none;
    line-height: 28px;
    height: 28px;
    min-width: 200px;
  }
`;

export const LabelInput = styled.label`
  width: 100%;
  margin: 16px auto 0;
  display: grid;
  grid-template-columns: 140px 200px;
  justify-content: center;
  align-items: center;
  transform: translateX(-35px);
  & span {
    margin-right: 12px;
    text-align: right;
  }
  & input {
    box-shadow: 1px 2px 10px -2px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    padding: 4px 6px;
    border: none;
    line-height: 28px;
    height: 28px;
    min-width: 200px;
  }
`;

export const UpdateLabel = styled(Label)`
  margin: 16px 0 0 0;
  display: grid;
  grid-template-columns: 140px 200px;
  justify-content: center;
  align-items: center;
  transform: translateX(-35px);
  & div {
    & p {
      padding-left: 20px;
      font-size: 12px;
      opacity: 0.5;
      margin: 0;
    }
    & input + span:not(:empty) {
      padding-left: 8px;
      line-height: 24px;
    }
  }
`;
