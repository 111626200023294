import { createActions } from "redux-actions";
import { createRequestAction } from "../utils";

const clustersActions = {
  ...createActions({
    CREATE_DEMO_RECORDING: ({accountID, visitorID, sessionID, namespace}) => ({accountID, visitorID, sessionID, namespace}),
    CREATE_ISSUE_SESSION_VAULT: ({accountID, visitorID, sessionID}) => ({accountID, visitorID, sessionID}),
  }),
  ...createRequestAction("CREATE_DEMO_RECORDING"),
  ...createRequestAction("CREATE_ISSUE_SESSION_VAULT"),
};

export default clustersActions;
