import styled from "styled-components";
import { DropdownList, ExtendStyledTable } from "../tables/styles";

export const Container = styled.div`
  min-height: 75px;
  max-width: 1600px;
  margin: 0 auto;
  padding: 16px 15px;
  display: flex;
`;

export const EsMain = styled.div`
  padding: 20px 15px;
  background-color: rgb(37, 37, 41);
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EsMainHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-areas: "title . button";
  width: 100%;
  margin-bottom: 16px;
  background-color: rgb(48, 47, 53);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  padding: 10px 16px;
  border-radius: 4px;
  & h2 {
    color: rgb(214, 214, 216);
    font-size: 24px;
    margin: 0;
  }
  & button {
    border: none;
    padding: 8px 16px;
    background-color: rgb(70, 99, 246);
    height: fit-content;
    width: fit-content;
    grid-area: button;
    justify-self: flex-end;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active,
    &:focus {
      outline: 0;
    }
    &:hover {
      cursor: pointer;
      & svg {
        transform: rotate(180deg);
      }
    }
    & svg {
      transform-origin: 50% 50%;
      color: rgb(247, 250, 255);
      transition: transform 0.3s ease;
    }
  }
`;

export const EsData = styled.div`
  padding: 16px;
  background-color: rgb(48, 47, 53);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
`;

export const EsDataContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  width: 100%;
  /* @media (max-width: 1600px) {
    grid-template-columns: repeat(2, 1fr);
  } */
`;

export const EsDataHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  margin-bottom: 24px;
  & div.alert {
    text-align: center;
    background-color: #19191d00;
    color: rgb(214, 214, 216);
    font-size: 18px;
    position: relative;
    border: none;
    margin: 0;
    padding: 0;
    &.alert:nth-of-type(1)::after,
    &.alert:nth-of-type(2)::after {
      content: "";
      width: 2px;
      height: 75%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background-color: rgb(89, 115, 232);
      border-radius: 4px;
    }
  }
`;

export const EsDataSync = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 16px;
  & div {
    padding: 4px 0;
    margin: 0 4px;
    text-align: center;
    font-size: 14px;
  }
`;

export const EsDataLeased = styled.div`
  & p {
    padding: 0;
    margin: 0 0 16px;
    text-align: center;
  }
`;

export const EsSessions = styled.div`
  margin: 16px 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 8px;
  row-gap: 8px;
  color: #000;
  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    background-color: rgb(25, 25, 29);
    border-radius: 4px;
    & p {
      margin: 0;
      color: rgb(214, 214, 216);
      font-size: 12px;
      line-height: normal;
      & .success-synced {
        color: #62c462;
      }
      & .warning-synced {
        color: orange;
      }
      & .danger-synced {
        color: rgb(231, 75, 92);
      }
      &:last-child {
        margin-top: 3px;
        font-size: 13px;
        line-height: normal;
      }
      &.danger {
        color: rgb(231, 75, 92);
      }
      &.inherit-a button {
        background: inherit;
        border: 0;
        cursor: pointer;
        outline: none;
        color: #fff;
        &:hover {
          color: #afafb3;
          text-decoration: underline;
        }
      }
    }
  }
`;

export const StyledClusterErrors = styled.div`
  margin: 16px 0 0;
  padding: 0;
  color: #000;
  & div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    background-color: rgb(25, 25, 29);
    border-radius: 4px;
    & p {
      margin: 0;
      color: rgb(214, 214, 216);
      font-size: 12px;
      line-height: normal;
      &:last-child {
        margin-top: 4px;
        font-size: 14px;
        line-height: normal;
      }
      &.danger {
        color: rgb(231, 75, 92);
      }
      &.inherit-a button {
        background: inherit;
        border: 0;
        cursor: pointer;
        outline: none;
        color: #4663f5;
      }
    }
  }
`;

export const EsEvents = styled(EsSessions)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 8px;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
`;

export const ExtendDropdownList = styled(DropdownList)`
  & .dropdown-menu {
    border: 1px solid #000;
    border-radius: 4px;
  }

  & .btn.btn-secondary {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    outline: 0;
    &:focus {
      box-shadow: none;
    }
    & svg {
      font-size: 18px;
      transform: translateY(-1px);
    }
  }
`;

export const CustomTable = styled(ExtendStyledTable)`
  &.table {
    & tbody tr:hover {
      cursor: pointer;
      & td:first-of-type {
        text-decoration: underline;
      }
    }
    & tr th {
      padding: 4px 8px !important;
      height: 30px;
      &:last-child {
        text-align: right;
      }
      & button {
        font-size: 11px;
        height: auto;
      }
    }
    & tr td {
      padding: 4px 8px !important;
      height: 26px;
    }
  }
`;
