import { handleActions } from "redux-actions";
import userActions from "./actions";
import update from "immutability-helper";

const defaultState = {
  user: null,
};

const userReducer = handleActions(
  {
    [userActions.USER_SIGN_IN]: (state, action) => {
      const { user } = action.payload;
      return update(state, { $set: { user: user } });
    },
    [userActions.USER_SIGN_OUT]: (state, action) => {
      return update(state, { $set: { user: null } });
    },
  },
  defaultState
);

export default userReducer;
