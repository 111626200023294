import { all, fork } from "redux-saga/effects";
import { createRequest } from "../utils";
import { partnersActions } from ".";
import api from "../../utils/api";

const getPartnersRequest = createRequest(partnersActions.GET_PARTNERS, api.get, (action) => {
  const { from } = action.payload;
  return {
    url: `/partners?&from=${from}&limit=12`,
  };
});

export default function* rootSaga() {
  yield all([fork(getPartnersRequest)]);
}
