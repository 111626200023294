import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import Layout from "../components/layout/layout";
import accountActions from "../redux/accounts/actions";
import accountSelectors from "../redux/accounts/selectors";
import { reduxLoaderSelectors } from "../redux/redux-loader";
import AccountDetailsUI from "../components/accountDetails/AccountDetails";
import Loading from "../components/styled/StyledLoading";

const AccountDetailsView = ({
  getAccount,
  accountData,
  getAccountState: { isLoading, isDone },
  match,
  searchEmail,
  email,
  getAccounts,
  list,
  limit,
  deleteAccountState,
}) => {
  const pullAccountData = useCallback(
    (id) => {
      getAccount(id);
    },
    [getAccount]
  );

  useEffect(() => {
    if (email && list.length === 1) {
      searchEmail("");
      getAccounts("", "creation_date", "DESC", 0 * limit, "", "");
    }
    if (accountData && accountData?.account?.account_id === match.params.id) return;
    pullAccountData(match.params.id);
  }, [accountData, email, getAccounts, limit, list.length, match.params.id, pullAccountData, searchEmail]);

  if (!accountData && !deleteAccountState.isLoading && deleteAccountState.isDone && !deleteAccountState.isError) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {isLoading && <Loading />}
      {accountData && accountData?.account?.account_id === match.params.id && isDone && (
        <AccountDetailsUI data={accountData} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return (state) => {
    return {
      accountData: accountSelectors.getAccount(state),
      getAccountState: reduxLoaderSelectors.getLoadingState(state, accountActions.getAccount),
      email: accountSelectors.getSearchEmail(state),
      list: accountSelectors.getAccountsList(state),
      limit: accountSelectors.getLimit(state),
      deleteAccountState: reduxLoaderSelectors.getLoadingState(state, accountActions.deleteAccount),
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAccount: accountActions.getAccount,
      searchEmail: accountActions.searchEmail,
      getAccounts: accountActions.getAccounts,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Layout(AccountDetailsView));
