import firebase from 'firebase/app';
import 'firebase/auth';
import {firebaseConfig} from './config';

firebase.initializeApp(firebaseConfig);

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider()

export const auth = firebase.auth();

export default firebase;