import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ModalHeader, ModalBody } from "reactstrap";

import { StyledModal, StyledForm } from "./UpdatePlan";
import StyledButton from "../styled/StyledButton";
import accountActions from "../../redux/accounts/actions";
import reduxLoaderActions from "../../redux/redux-loader/actions";
import { reduxLoaderSelectors } from "../../redux/redux-loader";
import { MdDeleteForever } from "react-icons/md";

export const Input = styled.input`
  box-shadow: 1px 2px 10px -2px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 4px 6px;
  border: none;
  line-height: 28px;
  height: 28px;
  min-width: 200px;
`;

const DeleteAccountModal = ({
  setOpen,
  isOpen,
  accountID,
  deleteAccount,
  getDeleteAccountState: { isLoading, isDone, isError },
  resetDeleteAccountState,
}) => {
  const input = useRef(null);

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    resetDeleteAccountState();
    return function cleanup() {
      resetDeleteAccountState();
    };
  }, [resetDeleteAccountState]);

  const addData = async (e) => {
    e.preventDefault();

    try {
      await deleteAccount(accountID);
    } catch (e) {
      if (isError) console.error(e);
    } finally {
      if (isDone && !isError) setOpen(false);
    }
  };

  return (
    <StyledModal
      isOpen={isOpen}
      toggle={() => setOpen(!isOpen)}
      modalTransition={{ timeout: 20 }}
      backdropTransition={{ timeout: 20 }}
      onOpened={() => input.current && input.current.focus()}
    >
      <ModalHeader toggle={() => setOpen(!isOpen)}>Delete account</ModalHeader>
      <ModalBody>
        <StyledForm onSubmit={(e) => addData(e)}>
          <p>Are you sure? Type 'delete' to delete account</p>
          <Input
            type="text"
            onChange={(e) => setInputValue(e.target.value.toLowerCase())}
            placeholder="delete"
            ref={input}
          />
          <StyledButton
            className="danger"
            isLoading={isLoading}
            disabled={inputValue !== "delete" || isLoading}
            loading
            type="submit"
            as="button"
            style={{ margin: "35px auto 0", border: "none" }}
          >
            <MdDeleteForever />
            Delete account
          </StyledButton>
        </StyledForm>
      </ModalBody>
    </StyledModal>
  );
};

export default connect(
  (state) => (state) => {
    return {
      getDeleteAccountState: reduxLoaderSelectors.getLoadingState(state, accountActions.deleteAccount),
    };
  },
  (dispatch) => {
    return bindActionCreators(
      {
        deleteAccount: accountActions.deleteAccount,
        resetDeleteAccountState: () => reduxLoaderActions.resetReduxLoaderState(accountActions.deleteAccount),
      },
      dispatch
    );
  }
)(DeleteAccountModal);
