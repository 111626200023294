import React, { useEffect, useState } from "react";
import Layout from "../components/layout/layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clustersSelectors from "../redux/clusters/selectors";
import clustersActions from "../redux/clusters/actions";
import { Container, EsMain, EsMainHeader, EsDataContainer } from "../components/styled/ClustersStyles";
import SingleCluster from "../components/singleCluster";
import { IoMdRefresh } from "react-icons/io";
import _ from "lodash";

const Clusters = ({
  getStats,
  getClusters,
  getErrorTotal,
  getClusterApiErrors,
  clusterApiErrors,
  errorTotal,
  data,
  time,
}) => {
  const [refreshDates, setRefreshDate] = useState([new Date().toTimeString().split(" ")[0]]);

  useEffect(() => {
    if (!data || data.length === 0) {
      getClusters();
      getStats();
      getClusterApiErrors();
    }

    const updateClusters = setInterval(() => {
      try {
        getClusters();
        setRefreshDate((prevDates) => [...prevDates, new Date().toTimeString().split(" ")[0]]);
      } catch (e) {
        console.log(e);
      }
    }, 20000);
    return () => clearInterval(updateClusters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const result = _(data)
    .groupBy("es_main1")
    .map((g) => _.mergeWith({}, ...g, (obj, src) => (_.isArray(obj) ? obj.concat(src) : undefined)))
    .value();

  function onErrorTotalClick(dataType, clusterHashID) {
    getErrorTotal(dataType, clusterHashID);
  }

  const renderElements = (info) => {
    const clusters = result.map((esmain, i) => {
      const cluster = _.flatten(Object.entries(esmain));
      return (
        <EsMain key={i}>
          <EsMainHeader>
            <h2>{cluster[0]}</h2>
            <button
              onClick={() => {
                getClusters();
                setRefreshDate((prevDates) => [...prevDates, new Date().toTimeString().split(" ")[0]]);
              }}
            >
              <IoMdRefresh />
            </button>
            <div />
          </EsMainHeader>
          <EsDataContainer>
            {info.length > 0 &&
              Object.entries(cluster[1]).map((esdata, idx) => (
                <SingleCluster
                  clusterErrors={clusterApiErrors && clusterApiErrors[cluster[0] + "." + esdata[0]]}
                  errorTotal={errorTotal}
                  onErrorTotalClick={onErrorTotalClick}
                  clusterTitle={esdata[0]}
                  clusterData={esdata[1]}
                  time={time}
                  key={idx}
                  refreshDates={refreshDates}
                />
              ))}
          </EsDataContainer>
        </EsMain>
      );
    });
    return clusters;
  };
  return (
    <>
      <Container>{renderElements(data)}</Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: clustersSelectors.getClusters(state),
    time: clustersSelectors.getClustersTime(state),
    error: clustersSelectors.getClustersErrors(state),
    baseCluster: clustersSelectors.getBaseCluster(state),
    errorTotal: clustersSelectors.getErrorTotal(state),
    clusterApiErrors: clustersSelectors.getClusterApiErrors(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStats: clustersActions.getStats,
      getClusters: clustersActions.getClusters,
      getErrorTotal: clustersActions.getErrorTotal,
      getClusterApiErrors: clustersActions.getClusterApiErrors,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout(Clusters));
