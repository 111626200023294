import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ModalHeader, ModalBody, Modal } from "reactstrap";
import StyledButton from "../styled/StyledButton";
import { connect } from "react-redux";
import { accountActions } from "../../redux/accounts";
import { bindActionCreators } from "redux";
import * as Styled from "../styled/FormStyles";
import { reduxLoaderSelectors } from "../../redux/redux-loader";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 450px;
  }
  .modal-header {
    padding: 1rem 2rem;
  }
  button.close span {
    color: red;
    text-shadow: none;
    font-size: 35px;
  }
  .modal-title {
    line-height: 38px;
  }
  .modal-body {
    padding: 2rem 0.5rem;
  }
`;

const FlagsBox = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: 140px 200px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px auto 0;
  position: relative;
  transform: translateX(-35px);
  &::before {
    content: "";
    position: absolute;
    width: 80%;
    height: 1px;
    background-color: #fff;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50% + 35px));
  }
  & span {
    margin-right: 12px;
    text-align: right;
  }
  & div {
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    label {
      margin: 0;
      justify-content: center;
      &:not(:last-of-type) {
        margin-right: 16px;
      }
      & span {
        margin-right: 0;
        text-align: left;
      }
      & input {
        margin-right: 12px;
      }
    }
  }
`;

const PlanIDInput = styled.input`
  color: #999;
`;

const Price = styled.div`
  margin: 16px auto 0;
  display: grid;
  grid-template-columns: 140px 200px;
  justify-content: center;
  align-items: center;
  transform: translateX(-35px);
  & p {
    font-size: 16px;
    margin: 0;
  }
`;

const UpdatePlan = ({ data, setOpen, isOpen, plans, pricingModel, updatePlan, getUpdatePlanState: { isDone, isError, isLoading } }) => {
  const { currency, plan, plan_id, retention, paid, account_id } = data;

  const planID = Object.keys(plans).find((id) => id === plan_id);

  const [currentPlanID, setPlanID] = useState(planID);
  const [currentPlan, setPlan] = useState(plan);

  const [currentPricing, setCurrentPricing] = useState(pricingModel.pricing[currentPlan] || []);

  const [currentCurrency, setCurrency] = useState(currency);
  const [currentRetention, setRetention] = useState(retention);
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [units, setUnits] = useState(currentPricing ? currentPricing[0]?.unit : 0);

  const [currentPaidFlag, setPaidFlag] = useState(paid || false);
  const [currentTrialFlag, setTrialFlag] = useState(currentPlan === "trial");

  const [updateSubscription, setUpdateSubscription] = useState(false);

  const isNotFreemium = currentPlan !== "free";
  const showMorePanels = updateSubscription && isNotFreemium;

  const planRetentions = pricingModel.retentions[currentPlan] || [];
  const planFeatures = pricingModel.features[currentPlan] || {};

  useEffect(() => {
    const pricing = pricingModel.pricing[currentPlan] || [];
    setCurrentPricing(pricing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan]);

  useEffect(() => {
    const newPlan = plans[currentPlanID];

    if (!newPlan) {
      return;
    }

    setPlan(newPlan.plan.name);
    setCurrency(newPlan.plan.currency);
    setRetention(newPlan.plan.retention);
    setBillingCycle(newPlan.plan.cycle);

    const pricing = pricingModel.pricing[newPlan.plan.name];
    if (pricing && pricing.length) {
      setUnits(pricing[0].unit);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlanID]);

  const addData = async (e) => {
    e.preventDefault();
    try {
      const JSONData = JSON.stringify({
        plan: currentPlan,
        paid: currentPaidFlag,
        trial: currentTrialFlag,
        currency: currentCurrency,
        retention: currentRetention,
        billing_cycle: billingCycle,
        update_subscription: updateSubscription,
        subscription_plan_id: currentPlanID,
        session_units: units,
      });
      await updatePlan(JSONData, account_id);
    } catch (e) {
      console.error(e);
    } finally {
      if (isDone && !isError) {
        setOpen(false);
      }
    }
  };

  function getPrice() {
    const pricing = currentPricing.find((p) => p.unit === units);

    if (!pricing || !pricing.prices) {
      return null;
    }

    const perRetention = pricing.prices[currentRetention + "m"];
    if (!perRetention) {
      return null;
    }

    const perCurrency = perRetention[currency];
    if (!perCurrency) {
      return null;
    }

    const perCycle = perCurrency[billingCycle || "monthly"];
    if (!perCycle) {
      return null;
    }

    return perCycle;
  }

  const formatPrice = () => {
    const price = getPrice();

    if (!price) {
      return null;
    }

    const currency = currentCurrency?.toUpperCase();

    switch (currency) {
      case "USD":
        return `${price} $`;
      case "EUR":
        return `${price} €`;
      case "PLN":
        return `${price} PLN`;
      default:
        return `${price}`;
    }
  };

  const handleNewPlan = (e) => {
    const newPlan = plans[e.target.value];
    setPlanID(e.target.value);

    switch (newPlan.type) {
      case "partner":
        setTrialFlag(false);
        setPaidFlag(false);
        break;
      case "trial":
        setTrialFlag(true);
        setPaidFlag(false);
        break;
      case "paid":
        setTrialFlag(false);
        setPaidFlag(true);
        break;
      default:
        setTrialFlag(false);
        setPaidFlag(false);
        return;
    }
  };

  return (
    <StyledModal isOpen={isOpen} toggle={() => setOpen(!isOpen)} modalTransition={{ timeout: 20 }} backdropTransition={{ timeout: 20 }}>
      <ModalHeader toggle={() => setOpen(!isOpen)}>Update plan</ModalHeader>
      <ModalBody>
        <StyledForm onSubmit={(e) => addData(e)}>
          <Styled.LabelSelect htmlFor="plan">
            <span style={{ alignSelf: "flex-start", marginTop: 3 }}> Plan:</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <select name="plan" id="plan" defaultValue={planID} onChange={(e) => handleNewPlan(e)} required>
                {Object.entries(plans).map(([id]) => (
                  <option key={id} value={id} selected={planID === id}>
                    {id}
                  </option>
                ))}
              </select>
              <p style={{ margin: "4px 0 0 0", fontSize: 12, opacity: 0.5, textAlign: "center", whiteSpace: "nowrap" }}>{`${planFeatures.websites} websites, ${planFeatures.team_members} agents`}</p>
            </div>
          </Styled.LabelSelect>
          <FlagsBox>
            <span>Flags: </span>
            <div>
              <Styled.Label htmlFor="trial">
                <input
                  id="trial"
                  type="checkbox"
                  checked={currentTrialFlag}
                  onChange={(e) => {
                    setTrialFlag(e.target.checked);
                  }}
                />{" "}
                <span>Trial</span>
              </Styled.Label>
              <Styled.Label htmlFor="paid">
                <input id="paid" type="checkbox" checked={currentPaidFlag} onChange={(e) => setPaidFlag(e.target.checked)} /> <span>Paid</span>
              </Styled.Label>
            </div>
          </FlagsBox>
          {isNotFreemium && (
            <Styled.UpdateLabel htmlFor="update">
              <div />
              <div>
                <input type="checkbox" name="update" id="update" defaultChecked={updateSubscription} onChange={(e) => setUpdateSubscription(e.target.checked)} />
                <span>Update subscription</span>
                <p>
                  <span>update subscription in Chargebee</span>
                </p>
              </div>
            </Styled.UpdateLabel>
          )}
          {showMorePanels && (
            <Styled.LabelSelect htmlFor="currency">
              <span>Currency:</span>
              <select name="currency" id="currency" required onChange={(e) => setCurrency(e.target.value)} defaultValue={currentCurrency} value={currentCurrency}>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                {/*<option value="PLN">PLN</option>*/}
              </select>
            </Styled.LabelSelect>
          )}
          {showMorePanels && (
            <Styled.LabelSelect htmlFor="billing-cycle">
              <span>Billing cycle:</span>
              <select name="billing-cycle" id="billing-cycle" required onChange={(e) => setBillingCycle(e.target.value)} value={billingCycle} defaultValue={billingCycle}>
                <option value="monthly">monthly</option>
                <option value="annual">annual</option>
              </select>
            </Styled.LabelSelect>
          )}
          {showMorePanels && (
            <Styled.LabelSelect htmlFor="retention">
              <span>Retention:</span>
              <select name="retention" id="retention" defaultValue={currentRetention} value={currentRetention} onChange={(e) => setRetention(parseInt(e.target.value))} required>
                {planRetentions.map((item) => (
                  <option key={item} value={item}>
                    {item === 1 ? `${item} month` : `${item} months`}
                  </option>
                ))}
              </select>
            </Styled.LabelSelect>
          )}
          {showMorePanels && (
            <Styled.LabelSelect htmlFor="units">
              <span>Units:</span>
              <select name="units" id="units" defaultValue={units} onChange={(e) => setUnits(parseFloat(e.target.value))} value={units} required>
                {currentPricing.map((item) => (
                  <option key={item.unit} value={item.unit}>
                    {item.unit}
                  </option>
                ))}
              </select>
            </Styled.LabelSelect>
          )}
          <Styled.LabelInput htmlFor="planid">
            <span>Plan ID:</span>
            <PlanIDInput type="text" value={currentPlanID} onChange={(e) => setPlanID(e.target.value)} />
          </Styled.LabelInput>
          <Price>
            <div></div>
            <p>
              <strong>{`Price: ${formatPrice()}`}</strong>
            </p>
          </Price>
          <StyledButton isLoading={isLoading} loading as="button" secondary type="submit" style={{ margin: "35px auto 0", border: "none" }}>
            Update
          </StyledButton>
        </StyledForm>
      </ModalBody>
    </StyledModal>
  );
};

export default connect(
  (state) => (state) => {
    return {
      getUpdatePlanState: reduxLoaderSelectors.getLoadingState(state, accountActions.updatePlan),
    };
  },
  (dispatch) => {
    return bindActionCreators(
      {
        updatePlan: accountActions.updatePlan,
      },
      dispatch
    );
  }
)(UpdatePlan);
