import {all, fork} from "redux-saga/effects";
import {createRequest} from "../utils";
import actions from "./actions";
import api from "../../utils/api";

const createDemoRecordingRequest = createRequest(actions.CREATE_DEMO_RECORDING, api.post, (action) => {
    const {accountID, visitorID, sessionID, namespace} = action.payload;

    const body = {
        account_id: accountID,
        visitor_id: visitorID,
        session_id: sessionID,
        namespace
    }

    return {url: `/recording/demo`, body: JSON.stringify(body)};
});

const createIssueSessionVaultRequest = createRequest(actions.CREATE_ISSUE_SESSION_VAULT, api.post, (action) => {
    const {accountID, visitorID, sessionID} = action.payload;

    const body = {
        account_id: accountID,
        visitor_id: visitorID,
        session_id: sessionID,
    }

    return {url: `/issue-session-vault`, body: JSON.stringify(body)};
});

export default function* rootSaga() {
    yield all([
        fork(createDemoRecordingRequest),
        fork(createIssueSessionVaultRequest)
    ]);
}
