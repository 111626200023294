import { handleActions, combineActions } from "redux-actions";
import accountActions from "./actions";
import update from "immutability-helper";
import cogoToast from "cogo-toast";
import queryString from "query-string";

const { page } = queryString.parse(window.location.search);

const defaultState = {
  list: [],
  count: 0,
  currentPage: page ? parseInt(page) : 0,
  limit: 12,
  error: "",
};

const partnersReducer = handleActions(
  {
    [accountActions.GET_PARTNERS_SUCCESS]: (state, action) => {
      const { accounts, count } = action.payload;
      if (accounts?.length === 0) cogoToast.error("Nothing found");
      const { currentPage } = state;
      window.history.pushState("", "", `/partners?page=${currentPage + 1}`);
      return update(state, { list: { $set: accounts }, count: { $set: count } });
    },
    [accountActions.CURRENT_PARTNERS_PAGE]: (state, action) => {
      const { page } = action.payload;
      return update(state, { currentPage: { $set: page } });
    },
    [combineActions(accountActions.GET_PARTNERS_ERROR)]: (state, action) => {
      const errorMsg =
        action.payload.error.toString() ||
        action.payload.error.message ||
        action.payload.error.response.data.error.message;
      cogoToast.error(errorMsg);
      return update(state, { error: { $set: errorMsg } });
    },
  },
  defaultState
);

export default partnersReducer;
