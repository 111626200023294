import styled from "styled-components";

export const StyledToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 15px 16px 0;
  border-bottom: 1px solid #333;
  width: calc(100% - 30px);
  margin: 0 auto;
  min-height: 75px;
  max-width: 1600px;
`;
