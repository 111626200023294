import { createActions } from "redux-actions";
import { createRequestAction } from "../utils";

const clustersActions = {
  ...createActions({
    GET_CLUSTERS: () => {},
    GET_STATS: () => {},
    GET_ERROR_TOTAL: (dataType, clusterHashID) => ({dataType, clusterHashID}),
    GET_CLUSTER_API_ERRORS: () => ({})
  }),
  ...createRequestAction("GET_CLUSTERS"),
  ...createRequestAction("GET_STATS"),
  ...createRequestAction("GET_ERROR_TOTAL"),
  ...createRequestAction("GET_CLUSTER_API_ERRORS"),
};

export default clustersActions;
