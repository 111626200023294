import { all } from "redux-saga/effects";
import userSagas from "./user/saga";
import accountSagas from "./accounts/saga";
import blacklistSagas from "./blacklist/saga";
import clustersSagas from "./clusters/saga";
import partnersSagas from "./partners/saga";
import recordingSaga from "./recording/saga";
import integrationsSaga from "./integrations/saga";

export default function* rootSaga(getState) {
  yield all([
    userSagas(),
    accountSagas(),
    blacklistSagas(),
    clustersSagas(),
    recordingSaga(),
    partnersSagas(),
    integrationsSaga(),
  ]);
}
