import React, { useState, useEffect, useCallback } from "react";
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropdownMenu, DropdownItem, DropdownToggle, Progress } from "reactstrap";
import queryString from "query-string";
import _ from "lodash";
import { StyledLabel, StyledInputSearch, ToolbarBox, FiltersDropdown, ResetButton, ClearQueryButton, PartnerLabel } from "../components/styled/DashboardStyles";
import Layout from "../components/layout/layout";
import StyledButton from "../components/styled/StyledButton";
import StyledLoading from "../components/styled/StyledLoading";
import { StyledTable } from "../components/styled/BlacklistStyles";
import { accountSelectors, accountActions } from "../redux/accounts";
import { reduxLoaderSelectors } from "../redux/redux-loader";
import GeneratedTokens from "../components/mixins/generatedTokens";
import { BsSearch } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { StyledToolbar } from "../components/styled/ToolbarStyles";
import CustomButton from "../components/styled/StyledButton";
import DateParagraph from "../components/styled/DateParagraph";
import { StyledButton as OrderButton } from "../components/styled/TableOrderButton";

const GenerateGhostLoginColumn = connect(
  (state, ownProps) => (state) => {
    return {
      ghostLogin: accountSelectors.getGhostLoginData(state),
    };
  },
  (dispatch) => {
    return bindActionCreators(
      {
        getGhostLogin: accountActions.ghostLogin,
        clearToken: accountActions.clearToken,
      },
      dispatch
    );
  }
)(({ login, getGhostLogin, clearToken, ghostLogin }) => {
  return (
    <td style={{ maxWidth: 100, height: 40 }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {ghostLogin.token && login === ghostLogin.email ? (
          <GeneratedTokens ghostLogin={ghostLogin} />
        ) : (
          <>
            <div style={{ display: "flex" }}>
              <StyledButton
                as="button"
                onClick={(e) => {
                  clearToken();
                  getGhostLogin(login);
                }}
                className="table-button"
                secondary
              >
                Generate
              </StyledButton>
            </div>
          </>
        )}
      </div>
    </td>
  );
});

const DashboardView = ({ email, list, getAccounts, currentPage, getAccountsState: { isLoading, isDone }, count, setCurrentPage, searchEmail, getPlans, plans, filters, setFilterType, setFilterValue, limit, setSortby, setSortOrder, sorting: { by, order } }) => {
  const { search } = useLocation();
  const { filter_type, filter_value } = queryString.parse(search);

  const [searchQuery, setSearchQuery] = useState(email);
  const [filterType, setCurrentFilterType] = useState(filter_type || "");
  const [filterValue, setCurrentFilterValue] = useState(filter_value || "");
  const [isFiltersOpen, setOpenFilters] = useState(false);
  const [typeOfFilter, setCurrentFilter] = useState(filter_type || "trial");
  const [isFiltersActive, setFiltersActive] = useState(search.length > 0 || false);

  const [planNames, setPlanNames] = useState([]);

  const pullData = useCallback(
    ({ page = currentPage, filterValue = "", filterType = "", by = "creation_date", order = "DESC", query = searchQuery }) => {
      return getAccounts(query, by, order, page * limit, filterType, filterValue);
    },
    [currentPage, getAccounts, searchQuery, limit]
  );

  const applyFilters = useCallback(() => {
    setFilterType(filterType || typeOfFilter);
    setFilterValue(filterValue);
    pullData({ page: 0, filterValue, filterType, by, order });
    setOpenFilters(!isFiltersOpen);
    setCurrentPage(0);
    setFiltersActive(true);
  }, [filterType, filterValue, isFiltersOpen, pullData, setCurrentPage, setFilterType, setFilterValue, by, order, typeOfFilter]);

  useEffect(() => {
    if (!plans) {
      return;
    }

    const uniq = new Set();
    // eslint-disable-next-line
    Object.entries(plans).map(([, { plan } = {}]) => {
      uniq.add(plan.name);
    });

    setPlanNames([...uniq]);
  }, [plans]);

  useEffect(
    () => {
      const params = queryString.parse(search);
      const page = params.page && parseInt(params.page) - 1;
      if (!plans) {
        getPlans();
      }
      if (isFiltersActive && isDone && list.length === 0) return;
      if (list.length === 0 && isFiltersOpen) return;
      if (params.filter_type && params.filter_value) {
        setFilterType(params.filter_type);
        setFilterValue(params.filter_value);
      }
      if (_.isEmpty(params)) {
        window.history.pushState("", "", `/?page=${currentPage + 1}&filter_type=${filters.type}&filter_value=${filters.value}&sortby=${by}&order=${order}&value=${searchQuery}`);
        setFilterType(filters.type);
        setFilterValue(filters.value);
      }
      if (params.page) {
        setCurrentPage(page);
        pullData({ page, filterValue, filterType, by, order });
      } else {
        if (list.length > 0) return;
        pullData({ currentPage, filterType, filterValue, by, order });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const status = ["active", "not_active", "blocked", "deleted"];
  const handlePage = async (direction) => {
    switch (direction) {
      case "prev":
        if (currentPage > 0) {
          const newPage = currentPage - 1;
          await setCurrentPage(newPage);
          if (filters.type !== filterType || filters.value !== filterValue) {
            pullData({
              page: newPage,
              filterValue: filters.value,
              filterType: filters.type,
              by,
              order,
            });
          } else {
            pullData({
              page: newPage,
              filterValue: filterValue,
              filterType: filterType,
              by,
              order,
            });
          }
          break;
        }
        break;
      case "next":
        if (Math.floor(count / limit) >= currentPage + 1) {
          const newPage = currentPage + 1;
          await setCurrentPage(newPage);
          if (filters.type !== filterType || filters.value !== filterValue) {
            pullData({
              page: newPage,
              filterValue: filters.value,
              filterType: filters.type,
              by,
              order,
            });
          } else {
            pullData({
              page: newPage,
              filterValue: filterValue,
              filterType: filterType,
              by,
              order,
            });
          }
        }
        break;
      default:
        return null;
    }
  };

  const onSearchKeyPress = async (e) => {
    if (e.key === "Enter") {
      setCurrentPage(0);
      await searchEmail(searchQuery);
      pullData({ page: 0, filterValue, filterType });
    }
  };

  const handleSortOrder = () => {
    const orders = ["DESC", "ASC", ""];
    const idx = orders.indexOf(order);
    const newOrder = orders[idx === 2 ? 0 : idx + 1];
    return newOrder;
  };

  const handleSortChange = (e, type) => {
    setSortby(type);

    setSortOrder(handleSortOrder());
    pullData({ by: type, order: handleSortOrder(), filterType, filterValue });
  };

  const renderHeader = () => {
    const sortValues = {
      DESC: <MdKeyboardArrowDown />,
      ASC: <MdKeyboardArrowUp />,
      "": "-",
    };

    return (
      <thead>
        <tr>
          <th>ID</th>
          <th>Login</th>
          <th>Plan</th>
          <th>Status</th>
          <th>Trial</th>
          <th>Trial PQL</th>
          <th>Sessions</th>
          <th style={{ display: "table-cell", alignItems: "center" }}>
            Creation date <OrderButton onClick={(e) => handleSortChange(e, "creation_date")}>{by === "creation_date" ? sortValues[order] : "-"}</OrderButton>
          </th>
          <th style={{ display: "table-cell", alignItems: "center" }}>
            End date <OrderButton onClick={(e) => handleSortChange(e, "end_date")}>{by === "end_date" ? sortValues[order] : "-"}</OrderButton>
          </th>
          <th style={{ textAlign: "center" }}>Ghost login</th>
        </tr>
      </thead>
    );
  };

  const RenderRow = (accountData) => {
    const { account_id, login, plan, status, trial, creation_date, end_date, sessions_count, sessions_count_month, trial_pql_score } = accountData;

    return (
      <tr key={login}>
        <td>
          <Link to={`/accounts/${account_id}`}>{account_id}</Link>
        </td>
        <td>
          <Link to={`/accounts/${account_id}`}>{login}</Link>
        </td>
        <td>{plan}</td>
        <td>{status}</td>
        <td>{trial ? "Yes" : "No"}</td>
        <td>{trial_pql_score}</td>
        <td>
          <Progress color="info" value={sessions_count} max={sessions_count_month}>{`${sessions_count || 0}/${sessions_count_month}`}</Progress>
        </td>
        <td>
          <DateParagraph data={creation_date} size="12" />
        </td>
        <td>
          <DateParagraph data={end_date} size="12" endDate />
        </td>
        <GenerateGhostLoginColumn login={login} />
      </tr>
    );
  };

  return (
    <>
      <StyledToolbar>
        <ToolbarBox>
          <p>Accounts</p>
          <p>{count}</p>
          <StyledLabel>
            {searchQuery ? (
              <ClearQueryButton onClick={() => setSearchQuery("")}>
                <IoMdClose />
              </ClearQueryButton>
            ) : (
              <BsSearch />
            )}
            <StyledInputSearch onKeyPress={(e) => onSearchKeyPress(e)} placeholder="Search email" onChange={(e) => setSearchQuery(e.target.value)} value={searchQuery || ""} autoFocus />
            <FiltersDropdown isOpen={isFiltersOpen} toggle={() => setOpenFilters(!isFiltersOpen)} size="sm">
              <DropdownToggle>
                {`Filter ${filters.type || filters.value ? `(${filters.type}, ${filters.value})` : ""}`}
                <MdKeyboardArrowDown />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem toggle={false} header>
                  Filters
                </DropdownItem>
                <DropdownItem tag="div" toggle={false}>
                  <div className="filter-types">
                    <label>
                      <input
                        type="radio"
                        name="type"
                        id="type-trial"
                        value="trial"
                        onChange={(e) => {
                          setCurrentFilter(e.target.value);
                          setCurrentFilterType("trial");
                          setCurrentFilterValue("");
                          setFiltersActive(false);
                        }}
                        defaultChecked={typeOfFilter === "trial"}
                      />
                      Trial
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="type"
                        id="type-plan"
                        value="plan"
                        onChange={(e) => {
                          setCurrentFilter(e.target.value);
                          setCurrentFilterType("plan");
                          setCurrentFilterValue("");
                          setFiltersActive(false);
                        }}
                        defaultChecked={typeOfFilter === "plan"}
                      />
                      Plan
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="type"
                        id="type-status"
                        value="status"
                        onChange={(e) => {
                          setCurrentFilter(e.target.value);
                          setCurrentFilterType("status");
                          setCurrentFilterValue("");
                          setFiltersActive(false);
                        }}
                        defaultChecked={typeOfFilter === "status"}
                      />
                      Status
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="type"
                        id="type-partner_id"
                        value="partner_id"
                        onChange={(e) => {
                          setCurrentFilter(e.target.value);
                          setCurrentFilterType("partner_id");
                          setCurrentFilterValue("");
                          setFiltersActive(false);
                        }}
                        defaultChecked={typeOfFilter === "partner_id"}
                      />
                      Partner ID
                    </label>
                  </div>
                </DropdownItem>
                {typeOfFilter === "trial" && (
                  <DropdownItem tag="div" toggle={false} header>
                    <p>Trial</p>
                    <label htmlFor="trial" className="trial">
                      <select
                        name="trial"
                        id="trial"
                        onChange={(e) => {
                          setCurrentFilterType("trial");
                          setCurrentFilterValue(e.target.value);
                          setFiltersActive(false);
                        }}
                        value={filterValue ? filterValue : ""}
                      >
                        <option value="">-</option>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                    </label>
                  </DropdownItem>
                )}
                {typeOfFilter === "plan" && (
                  <DropdownItem tag="div" toggle={false} header>
                    <p>Plan</p>
                    <label htmlFor="plan-types" className="trial">
                      <select
                        name="plan-types"
                        id="plan-types"
                        value={filterType || typeOfFilter}
                        onChange={(e) => {
                          setCurrentFilterType(e.target.value);
                          setCurrentFilterValue(filterValue || "");
                          setFiltersActive(false);
                        }}
                      >
                        <option value="plan">is</option>
                        <option value="plan_is_not">is not</option>
                      </select>
                      <select
                        name="plan"
                        id="plan"
                        onChange={(e) => {
                          setCurrentFilterType(filterType || "plan");
                          setCurrentFilterValue(e.target.value);
                          setFiltersActive(false);
                        }}
                        value={filterValue ? filterValue : ""}
                      >
                        <option value="">-</option>
                        {planNames &&
                          planNames.map((plan) => (
                            <option key={plan} value={plan}>
                              {plan}
                            </option>
                          ))}
                      </select>
                    </label>
                  </DropdownItem>
                )}
                {typeOfFilter === "status" && (
                  <DropdownItem tag="div" toggle={false} header>
                    <p>Status</p>
                    <label htmlFor="status" className="trial">
                      <select
                        name="status"
                        id="status"
                        onChange={(e) => {
                          setCurrentFilterType("status");
                          setCurrentFilterValue(e.target.value);
                          setFiltersActive(false);
                        }}
                        value={filterValue ? filterValue : ""}
                      >
                        <option value="">-</option>
                        {status.map((plan) => (
                          <option key={plan} value={plan}>
                            {plan}
                          </option>
                        ))}
                      </select>
                    </label>
                  </DropdownItem>
                )}
                {typeOfFilter === "partner_id" && (
                  <PartnerLabel htmlFor="partner_id_input">
                    <input type="text" placeholder="Partner ID" id="partner_id_input" onChange={({ target: { value } }) => setCurrentFilterValue(value)} onKeyDown={({ key }) => key === "Enter" && applyFilters()} value={filterValue} />
                  </PartnerLabel>
                )}
                <DropdownItem tag="div" className="save" toggle={false} header>
                  <CustomButton as="button" onClick={applyFilters} className="table-button" secondary={true} style={{ width: "100%", maxWidth: "100%", padding: "6px 4px" }}>
                    Apply filters
                  </CustomButton>
                  <div>
                    <ResetButton
                      onClick={() => {
                        setCurrentFilterType("");
                        setCurrentFilterValue("");
                        setFiltersActive(false);
                        setCurrentPage(0);
                        setFilterType("");
                        setFilterValue("");
                        setSearchQuery("");
                        searchEmail("");
                        return getAccounts("", "creation_date", "DESC", 0, "", "");
                      }}
                    >
                      Reset
                    </ResetButton>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </FiltersDropdown>
          </StyledLabel>
        </ToolbarBox>
      </StyledToolbar>
      {list.length > 0 && (
        <>
          <div style={{ overflowX: "auto", maxWidth: 1600, padding: "15px 0" }} className="mx-auto">
            <StyledTable style={{ minWidth: 1200 }} hover>
              {renderHeader()}
              <tbody>
                {list.map((accountData) => (
                  <RenderRow {...accountData} />
                ))}
              </tbody>
            </StyledTable>
          </div>
          <div
            style={{
              marginBottom: 48,
              display: "grid",
              gridTemplateColumns: "140px 100px 140px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledButton as="button" onClick={() => handlePage("prev")} disabled={currentPage === 0}>
              Previous page
            </StyledButton>
            {count && (
              <div style={{ margin: "0 10px", textAlign: "center" }}>
                {currentPage + 1} of {Math.ceil(count / limit)}
              </div>
            )}
            <StyledButton onClick={() => handlePage("next")} as="button" disabled={Math.floor(count / limit) < currentPage + 1}>
              Next page
            </StyledButton>
          </div>
        </>
      )}
      {isDone && list.length === 0 && (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
          <p style={{ margin: "0 16px 0 0" }}>
            No results{" "}
            <span role="img" aria-label="emoji">
              💁‍♂️
            </span>
          </p>{" "}
          <ResetButton
            to="/"
            onClick={() => {
              setCurrentFilterType("");
              setCurrentFilterValue("");
              setFiltersActive(false);
              setCurrentPage(0);
              setFilterType("");
              setFilterValue("");
              setSearchQuery("");
              searchEmail("");
              return getAccounts("", "creation_date", "DESC", 0, "", "");
            }}
          >
            Reset
          </ResetButton>
        </div>
      )}
      {isLoading && <StyledLoading />}
      {searchQuery && list.length === 1 && currentPage === 0 && email && <Redirect to={`/accounts/${list[0].account_id}`} />}
    </>
  );
};

export default connect(
  // mapStateToProps
  (state, ownProps) => (state) => {
    return {
      list: accountSelectors.getAccountsList(state),
      email: accountSelectors.getSearchEmail(state),
      count: accountSelectors.getAccountsCount(state),
      currentPage: accountSelectors.getCurrentPage(state),
      plans: accountSelectors.getAllPlans(state),
      filters: accountSelectors.getFilters(state),
      sorting: accountSelectors.getSorting(state),
      limit: accountSelectors.getLimit(state),
      getAccountsState: reduxLoaderSelectors.getLoadingState(state, accountActions.getAccounts),
    };
  },
  // mapDispatchToProps
  (dispatch) => {
    return bindActionCreators(
      {
        getAccounts: accountActions.getAccounts,
        searchEmail: accountActions.searchEmail,
        setCurrentPage: accountActions.currentPage,
        getPlans: accountActions.getPlans,
        setFilterType: accountActions.setFilterType,
        setFilterValue: accountActions.setFilterValue,
        setSortby: accountActions.setSortby,
        setSortOrder: accountActions.setSortOrder,
      },
      dispatch
    );
  }
)(Layout(DashboardView));
