import React from "react";
import { formatData } from "../../utils/formatter";
import { MoreAccountInfo, AccountDesc } from "./styles";

const AccountTable = ({ account, retention }) => {
  function title(obj) {
    return obj[0].charAt(0).toUpperCase() + obj[0].slice(1).replace(/_/g, " ")
  }

  function value(obj) {
    if (obj[0] === "plan_id") {
      return obj[1]
    }

    return obj[0] === "retention" ? `${retention} month` : formatData(obj[1])
  }

  return (
    <AccountDesc>
      {Object.entries(account).map((obj, i) => {
        return (
          <MoreAccountInfo key={i}>
            <p>
              <strong>{title(obj)}</strong>
            </p>
            <p>{value(obj)}</p>
          </MoreAccountInfo>
        );
      })}
    </AccountDesc>
  );
};

export default AccountTable;
