import React, { useState } from "react";
import styled from "styled-components";
import WebstiesTable from "../tables/Websites";
import AgentsTable from "../tables/Agents";

export const Box = styled.div`
  background-color: #252529;
  padding: 15px;
  overflow: scroll;
  border-radius: 0 8px 8px 0;
  & h2 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: #bdbcc9;
    display: block;
    position: relative;
    margin-top: 24px;
    &::before {
      content: "";
      width: 4px;
      height: 100%;
      background-color: #bdbcc9;
      position: absolute;
      left: -16px;
      top: 0;
      border-radius: 0 4px 4px 0;
    }
  }
  & p {
    font-size: 14px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  & h2 {
    margin: 0;
  }
  margin-bottom: 16px;
  margin-top: 24px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  margin-left: 16px;
  & p {
    margin: 0;
    margin-left: 8px;
  }
`;

const Info = ({ websites, agents, accountID }) => {
  const [filterDeleted, setFilterDeleted] = useState(true);

  const agentWithoutDeleted = agents.filter(({ status }) => status !== "deleted");

  return (
    <Box>
      <h2>WEBSITES</h2>
      <WebstiesTable websites={websites} />
      <Container>
        {" "}
        <h2>AGENTS ({agentWithoutDeleted.length})</h2>{" "}
        <Label htmlFor="filter-deleted">
          <input
            type="checkbox"
            name="filter-deleted"
            id="filter-deleted"
            checked={filterDeleted}
            onChange={(e) => setFilterDeleted(e.target.checked)}
          />
          <p>Filter deleted</p>
        </Label>
      </Container>
      <AgentsTable agents={filterDeleted ? agentWithoutDeleted : agents} accountID={accountID} />
    </Box>
  );
};

export default Info;
