import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ldsRing = keyframes`
from{
  transform: rotate(0deg);
}
to{
  transform: rotate(360deg);
}
`;

export const StyledButton = styled(Link)`
  background-color: ${({ secondary, reversed }) =>
    secondary ? (reversed ? "#fff" : "#4663f6") : reversed ? "#fff" : "#302f35"};
  padding: ${({ small }) => (small ? "7px 20px" : "8px 16px")};
  border-radius: 4px;
  transition: 0.2s ease;
  max-height: ${(props) => (props.small ? "38px" : "48px")};
  font-size: 14px;
  color: ${({ secondary, reversed }) => (secondary ? (reversed ? "#000" : "#fff") : reversed ? "#000" : "#d6d6d8")};
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  min-width: 140px;
  max-width: fit-content;
  outline: 0;
  display: block;
  text-decoration: none;
  white-space: nowrap;
  margin: ${(props) => (props.center ? "0 auto" : 0)};
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.secondary ? null : "2px solid transparent")};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  &:focus {
    color: ${(props) => (props.reversed ? "#000" : "#fff")};
    outline: 0;
  }
  &:hover {
    color: ${({ secondary, reversed }) => (secondary ? (reversed ? "#fff" : "#000") : reversed ? "#fff" : "#8598f9")};
    background-color: ${({ secondary, reversed }) =>
      secondary ? (reversed ? "#000" : "#fff") : reversed ? "#4663f6" : "transparent"};
    outline: 0;
    text-decoration: none;
    box-shadow: ${(props) => (props.navbar ? null : "0 1px 6px rgba(0, 0, 0, 0.07)")};
    border: ${(props) => (props.secondary ? null : "2px solid #8598f9")};
    & svg {
      fill: #8598f9;
    }
    & .lds-ring {
      div {
        border: 2px solid #000;
        border-color: #000 transparent transparent transparent;
      }
    }
  }
  &:disabled {
    opacity: 0.5;
  }

  & svg {
    margin-right: 8px;
    fill: #bcbcc8;
  }

  &.btn-navbar {
    padding: 8px 24px;
    font-weight: 700;
    min-width: auto;
    border-radius: 11px;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #b4b4bf;
    border: none;
    position: relative;
    letter-spacing: 0.2px;
    height: 100%;
    box-shadow: none;
    &::after {
      content: "";
      width: 100%;
      height: 3px;
      background-color: #4663f6;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 4px 4px 0 0;
      opacity: 0;
      transition: opacity 0.2s ease;
    }
    &:hover {
      transform: scale(1);
      box-shadow: none;
      color: #fff;
    }
    &.active {
      color: #fff;
      &::after {
        opacity: 1;
      }
    }
    @media (max-width: 1200px) {
      padding: 8px 20px;
    }
  }
  &.btn-navbar.logout {
    padding-right: 0;
    color: #aaa;
    white-space: nowrap;
    &:hover {
      color: #fff;
    }
  }
  &.table-button {
    padding: 2px 4px;
    font-size: 12px;
    min-width: 80px;
    max-width: 90px;
    font-weight: 500;
    border: 2px solid transparent;
  }
  &.danger {
    &:hover {
      background-color: #c53743;
      color: #fff;
      border: 2px solid transparent;
      & svg {
        fill: #fff;
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
  .lds-ring {
    display: flex;
    position: relative;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease;
    transform: translateX(0);
    &.active {
      transform: translateX(-6px);
    }
    & div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      margin: 0 8px 0 0;
      border: 2px solid #fff;
      border-radius: 50%;
      animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
`;

const Button = ({
  as,
  href,
  children,
  onClick,
  small,
  secondary,
  center,
  type,
  className,
  disabled,
  id,
  style,
  target,
  rel,
  reversed,
  navbar,
  loading,
  isLoading,
}) => {
  return (
    <StyledButton
      as={as}
      to={href}
      onClick={onClick}
      small={small}
      secondary={secondary}
      center={center}
      type={type}
      className={className}
      disabled={disabled}
      id={id}
      style={style}
      target={target}
      rel={rel}
      navbar={navbar}
      reversed={reversed}
    >
      {loading && isLoading && (
        <div className={["lds-ring", isLoading && "active"].join(" ")}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {children}
    </StyledButton>
  );
};

export default Button;

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  href: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]).isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
  small: PropTypes.bool,
  secondary: PropTypes.bool,
  center: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  reversed: PropTypes.bool,
  id: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
};

Button.defaultProps = {
  as: null,
  href: undefined,
  onClick: null,
  small: null,
  secondary: false,
  center: null,
  type: null,
  className: null,
  id: null,
  style: null,
  target: null,
  rel: null,
  disabled: false,
  reversed: false,
};
