import { handleActions } from "redux-actions";

const defaultState = {};
const reduxLoaderReducer = handleActions(
  {
    "@@redux-loader/LOADING": (state, action) => {
      const actionName = action.payload.id
        ? `${action.payload.action}_${action.payload.id}`
        : action.payload.action;
      return {
        ...state,
        [actionName]: {
          isLoading: true,
          isDone: false
        }
      };
    },
    "@@redux-loader/LOADED": (state, action) => {
      const actionName = action.payload.id
        ? `${action.payload.action}_${action.payload.id}`
        : action.payload.action;
      return {
        ...state,
        [actionName]: {
          isLoading: false,
          isDone: true
        }
      };
    },
    "@@redux-loader/ERROR": (state, action) => {
      const actionName = action.payload.id
        ? `${action.payload.action}_${action.payload.id}`
        : action.payload.action;
      return {
        ...state,
        [actionName]: {
          isLoading: false,
          isError: true,
          isDone: true,
          error: action.payload.error
        }
      };
    },
    RESET_REDUX_LOADER_STATE: (state, action) => {
      const actionName = action.payload.id
        ? `${action.payload.action}_${action.payload.id}`
        : action.payload.action;
      return {
        ...state,
        [actionName]: {
          isLoading: false,
          isDone: false,
          isError: false
        }
      };
    }
  },
  defaultState
);

export default reduxLoaderReducer;
