import { handleActions } from "redux-actions";
import clustersActions from "./actions";
import update from "immutability-helper";
import cogoToast from "cogo-toast";
import moment from "moment";
import _ from "lodash";

const initialState = {
  data: "",
  error: "",
  time: "",
  errorTotal: {},
  clusterApiErrors: null
};

const clustersReducer = handleActions(
  {
    [clustersActions.GET_CLUSTERS_SUCCESS]: (state, action) => {
      const { data } = state;
      const { payload } = action;
      delete payload.id;
      const date = moment().format("HH:mm:ss");

      const customizer = (objValue, srcValue) => {
        if (_.isArray(objValue)) {
          return objValue.concat(srcValue);
        }
      };

      const formatFetchedData = (cluster) => {
        let newArr = [];

        Object.entries(cluster).map((ent) => {
          if (_.isNumber(ent[1]) || _.isBoolean(ent[1])) {
            return newArr.push({ [ent[0]]: [ent[1]] });
          }
          return newArr.push({ [ent[0]]: ent[1] });
        });
        return newArr;
      };

      const formatNewData = (newData) => {
        return _.flatten(
          Object.entries(newData).map((esdata) =>
            Object.entries(esdata[1]).map((val) => {
              return { [esdata[0]]: { [val[0]]: formatFetchedData(val[1]) } };
            })
          )
        );
      };

      const formattedData = formatNewData(payload);

      if (data === "") {
        return update(state, { time: { $set: date }, data: { $set: formattedData } });
      } else {
        const newFormattedData = _.flattenDeep(
          data.map((esmain) =>
            Object.entries(esmain).map((esdata) =>
              Object.entries(esdata[1]).map((val) => {
                const newData = _.mergeWith(
                  _.flatten(Object.values(esdata[1])),
                  formatFetchedData(payload[esdata[0]][val[0]]),
                  customizer
                );
                return { [esdata[0]]: { [val[0]]: newData } };
              })
            )
          )
        );
        return update(state, { time: { $set: date }, data: { $set: newFormattedData } });
      }
    },
    [clustersActions.GET_CLUSTERS_ERROR]: (state, action) => {
      const { message } = action.payload.error;
      cogoToast.error(message);
      return update(state, { error: { $set: message } });
    },
      [clustersActions.GET_ERROR_TOTAL_SUCCESS]: (state, action) => {
        const {count, data_type, cluster_hash_id} = action.payload;

          return {
              ...state,
              errorTotal: {
                  ...state.errorTotal,
                  [cluster_hash_id + "." + data_type]: count
              }
          }
      },
      [clustersActions.GET_CLUSTER_API_ERRORS_SUCCESS]: (state, action) => {
          return {
              ...state,
              clusterApiErrors: action.payload.response
          };
      },
  },
  initialState
);

export default clustersReducer;
