import { createActions } from "redux-actions";
import { createRequestAction } from "../utils";

const partnersActions = {
  ...createActions({
    GET_PARTNERS: (from) => ({ from }),
    CURRENT_PARTNERS_PAGE: (page) => ({ page }),
  }),
  ...createRequestAction("GET_PARTNERS"),
  CURRENT_PARTNERS_PAGE: "CURRENT_PARTNERS_PAGE",
};

export default partnersActions;
