import { all, fork } from "redux-saga/effects";
import { createRequest } from "../utils";
import { accountActions } from ".";
import api from "../../utils/api";

const getAccountsRequest = createRequest(accountActions.GET_ACCOUNTS, api.get, (action) => {
  const { query, sort, order, from, filterType, filterValue } = action.payload;
  return {
    url: `/accounts?query=${encodeURIComponent(query)}&sort_by=${sort}&sort_order=${order}&from=${from}&filter_type=${filterType}&filter_value=${filterValue}&limit=12`,
  };
});

const getAccountRequest = createRequest(accountActions.GET_ACCOUNT, api.get, (action) => {
  const { accountID } = action.payload;
  return {
    url: `/accounts/${accountID}`,
  };
});

const getGhostLoginRequest = createRequest(accountActions.GHOST_LOGIN, api.get, (action) => {
  const { login } = action.payload;
  return { url: `/tools/ghost-login?login=${encodeURIComponent(login)}` };
});

const getAllPlans = createRequest(accountActions.GET_PLANS, api.get, (action) => {
  return { url: `/tools/plans` };
});

const updateAccountQuotas = createRequest(accountActions.UPDATE_ACCOUNT_QUOTAS, api.post, (action) => {
  const { data, id } = action.payload;
  return { url: `/accounts/${id}/quotas`, body: data };
});

const updateAccountSessionQuotasBonus = createRequest(accountActions.UPDATE_ACCOUNT_SESSION_QUOTAS_BONUS, api.post, (action) => {
  const { data, id } = action.payload;
  return { url: `/accounts/${id}/quotas-bonus`, body: data };
});

const updateAccount = createRequest(accountActions.UPDATE_ACCOUNT, api.put, (action) => {
  const { data, id } = action.payload;
  return { url: `/accounts/${id}`, body: data };
});

const deleteAccount = createRequest(accountActions.DELETE_ACCOUNT, api.delete, (action) => {
  return { url: `/accounts/${action.payload.id}` };
});

const updatePlan = createRequest(accountActions.UPDATE_PLAN, api.put, (action) => {
  const { data, id } = action.payload;
  return { url: `/accounts/${id}/plan`, body: data };
});

const updateAgent = createRequest(accountActions.UPDATE_AGENT, api.put, (action) => {
  const { agentID, accountID, data } = action.payload;
  return { url: `/accounts/${accountID}/agents/${agentID}`, body: data };
});

const getESStats = createRequest(accountActions.GET_ES_STATS, api.get, (action) => {
  const { accountID } = action.payload;
  return { url: `/accounts/${accountID}/es-stats` };
});

const getAccountProperties = createRequest(accountActions.GET_ACCOUNT_PROPERTIES, api.get, (action) => {
  const { accountID } = action.payload;
  return { url: `/accounts/${accountID}/properties` };
});

const addAccountProperty = createRequest(accountActions.ADD_ACCOUNT_PROPERTY, api.post, (action) => {
  const { accountID, data } = action.payload;
  return { url: `/accounts/${accountID}/properties`, body: data };
});

const deleteAccountProperty = createRequest(accountActions.DELETE_ACCOUNT_PROPERTY, api.delete, (action) => {
  const { accountID, property } = action.payload;
  return { url: `/accounts/${accountID}/properties/${property}` };
});

const getAPIErrors = createRequest(accountActions.GET_API_ERRORS, api.get, (action) => {
  const { accountID } = action.payload;
  return { url: `/accounts/${accountID}/api-errors` };
});
const getAccountLogs = createRequest(accountActions.GET_ACCOUNT_LOGS, api.get, (action) => {
  const { accountID } = action.payload;
  return { url: `/accounts/${accountID}/logs` };
});

export default function* rootSaga() {
  yield all([fork(getAccountsRequest), fork(getGhostLoginRequest), fork(getAccountRequest), fork(getAllPlans), fork(updateAccountQuotas), fork(updateAccountSessionQuotasBonus), fork(updateAgent), fork(updateAccount), fork(deleteAccount), fork(updatePlan), fork(getESStats), fork(getAccountProperties), fork(addAccountProperty), fork(deleteAccountProperty), fork(getAPIErrors), fork(getAccountLogs)]);
}
