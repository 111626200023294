import { handleActions, combineActions } from "redux-actions";
import accountActions from "./actions";
import update from "immutability-helper";
import cogoToast from "cogo-toast";

const defaultState = {
  list: [],
  count: 0,
  error: "",
};

const integrationsReducer = handleActions(
  {
    [accountActions.GET_INTEGRATIONS_SUCCESS]: (state, action) => {
      const { integrations } = action.payload;
      if (integrations?.length === 0) cogoToast.error("Nothing found");
      return update(state, { list: { $set: integrations }, count: { $set: integrations.length } });
    },
    [combineActions(accountActions.GET_INTEGRATIONS_ERROR)]: (state, action) => {
      const errorMsg =
        action.payload.error.toString() ||
        action.payload.error.message ||
        action.payload.error.response.data.error.message;
      cogoToast.error(errorMsg);
      return update(state, { error: { $set: errorMsg } });
    },
  },
  defaultState
);

export default integrationsReducer;
