import { all, fork } from "redux-saga/effects";
import { createRequest } from "../utils";
import { integrationsActions } from ".";
import api from "../../utils/api";

const getIntegrationsRequest = createRequest(integrationsActions.GET_INTEGRATIONS, api.get, (action) => {
  return {
    url: `/integrations`,
  };
});

export default function* rootSaga() {
  yield all([fork(getIntegrationsRequest)]);
}
