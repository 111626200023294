import React, { useState } from "react";
import styled from "styled-components";
import { ModalHeader, ModalBody, Modal } from "reactstrap";
import StyledButton from "../styled/StyledButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { accountActions } from "../../redux/accounts";
import { bindActionCreators } from "redux";
import { parseDateToForm } from "../../utils/date";
import { formatFromNow } from "../../utils/formatter";
import * as Styled from "../styled/FormStyles";
import { reduxLoaderSelectors } from "../../redux/redux-loader";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ExtendStyledLabel = styled(Styled.LabelInput)`
  transform: translateX(0px);
`;
const ExtendStyledSelect = styled(Styled.LabelSelect)`
  transform: translateX(0px);
`;

export const StyledModal = styled(Modal)`
  .modal-header {
    padding: 1rem 2rem;
  }
  button.close span {
    color: red;
    text-shadow: none;
    font-size: 35px;
  }
  .modal-title {
    line-height: 38px;
  }
  .modal-body {
    padding: 2rem 1rem;
  }
`;

const QuotasForm = ({
  data,
  isOpen = false,
  setOpen,
  updateAccountQuotas,
  updateAccountSessionQuotasBonus,
  accountID,
  getUpdateAccountQuotas,
  getUpdateAccountSessionQuotasBonus
}) => {
  const [websitesLimit, setWebsitesLimit] = useState(data.websites_limit);
  const [agentsLimit, setAgentsLimit] = useState(data.agents_limit);
  const [sessionsLimit, setSessionsLimit] = useState(data.sessions_count_month);
  const [retention, setRetention] = useState(data.quota_retention);
  const [quotaResetDate, setQuotaResetDate] = useState(data.quota_reset_date);
  const [sessionBonus, setSessionBonus] = useState(data.session_bonus_count);

  const isLoading = getUpdateAccountQuotas.isLoading || getUpdateAccountSessionQuotasBonus.isLoading
  const isError = getUpdateAccountQuotas.isError || getUpdateAccountSessionQuotasBonus.isError
  const isDone = getUpdateAccountQuotas.isDone || getUpdateAccountSessionQuotasBonus.isDone

  const addData = async (e) => {
    e.preventDefault();
    try {
      const JSONData = JSON.stringify({
        agents_limit: agentsLimit,
        websites_limit: websitesLimit,
        sessions_limit: sessionsLimit,
        retention,
        quota_reset_date: quotaResetDate,
      });
      await updateAccountQuotas(JSONData, accountID);
    } catch (e) {
      if (isError) console.error(e);
    } finally {
      if (isDone && !isError) {
        setOpen(false);
      }
    }
  };

  async function addBonus(e) {
    e.preventDefault()

    const JSONData = JSON.stringify({
      bonus: sessionBonus,
    });

    updateAccountSessionQuotasBonus(JSONData, accountID)
  }

  return (
    <StyledModal
      isOpen={isOpen}
      toggle={() => setOpen(false)}
      modalTransition={{ timeout: 20 }}
      backdropTransition={{ timeout: 20 }}
    >
      <ModalHeader toggle={() => setOpen(false)}>Update quotas</ModalHeader>
      <ModalBody>
        <StyledForm>
          <ExtendStyledLabel htmlFor="agents">
            <span>Agents limit:</span>
            <input
              id="agents"
              type="number"
              name="agents"
              value={agentsLimit}
              onChange={(e) => setAgentsLimit(e.target.value !== "" ? parseInt(e.target.value) : "")}
              min="1"
              required
            />
          </ExtendStyledLabel>
          <ExtendStyledLabel htmlFor="websites">
            <span>Websites limit:</span>
            <input
              id="websites"
              type="number"
              name="websites"
              value={websitesLimit}
              onChange={(e) => setWebsitesLimit(e.target.value !== "" ? parseInt(e.target.value) : "")}
              min="1"
              required
            />
          </ExtendStyledLabel>
          <ExtendStyledLabel htmlFor="sessions">
            <span>Sessions limit:</span>
            <input
              id="sessions"
              type="number"
              name="sessions"
              value={sessionsLimit}
              onChange={(e) => setSessionsLimit(e.target.value !== "" ? parseInt(e.target.value) : "")}
              min="1"
              required
            />
          </ExtendStyledLabel>
          <ExtendStyledSelect htmlFor="retention">
            <span> Retention:</span>
            <select
              name="retention"
              id="retention"
              required
              onChange={(e) => setRetention(e.target.value !== "" ? parseInt(e.target.value) : "")}
              defaultValue={retention}
            >
              <option value={1}>1 month</option>
              <option value={3}>3 months</option>
              <option value={6}>6 months</option>
              <option value={12}>12 months</option>
            </select>
          </ExtendStyledSelect>
          <ExtendStyledLabel>
            <span style={{ alignSelf: "flex-start", marginTop: 4 }}>Quota reset date:</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <DatePicker
                selected={parseDateToForm(quotaResetDate)}
                onChange={(date) => setQuotaResetDate(date.toISOString())}
                dateFormat="dd/MM/yyyy"
                required
              />
              <p style={{ margin: "4px 0 0 4px", fontSize: 12, opacity: 0.5 }}>
                {formatFromNow(quotaResetDate, false, true)}
              </p>
            </div>
          </ExtendStyledLabel>
          <ExtendStyledLabel htmlFor="session_bonus">
            <span>Session bonus</span>
            <input
                id="session_bonus"
                type="number"
                name="session_bonus"
                value={sessionBonus}
                onChange={(e) => setSessionBonus(e.target.value !== "" ? parseInt(e.target.value) : "")}
                min="1"
                required
            />
          </ExtendStyledLabel>
          <div style={{
            display: "flex",
            gap: 10,
          }}>
            <StyledButton
                loading
                isLoading={isLoading}
                as="button"
                secondary
                type="button"
                style={{ margin: "35px auto 0", border: "none" }}
                onClick={addData}
            >
              Update
            </StyledButton>
            <StyledButton
                loading
                isLoading={isLoading}
                as="button"
                secondary
                type="button"
                style={{ margin: "35px auto 0", border: "none" }}
                onClick={addBonus}
            >
              Update bonus
            </StyledButton>
          </div>
        </StyledForm>
      </ModalBody>
    </StyledModal>
  );
};

export default connect(
  (state) => (state) => {
    return {
      getUpdateAccountQuotas: reduxLoaderSelectors.getLoadingState(state, accountActions.updateAccountQuotas),
      getUpdateAccountSessionQuotasBonus: reduxLoaderSelectors.getLoadingState(state, accountActions.updateAccountSessionQuotasBonus),
    };
  },
  (dispatch) => {
    return bindActionCreators(
      {
        updateAccountQuotas: accountActions.updateAccountQuotas,
        updateAccountSessionQuotasBonus: accountActions.updateAccountSessionQuotasBonus,
      },
      dispatch
    );
  }
)(QuotasForm);
