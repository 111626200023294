import { handleActions, combineActions } from "redux-actions";
import blacklistActions from "./actions";
import update from "immutability-helper";
import cogoToast from "cogo-toast";
const initialState = {
  blacklist: [],
  error: "",
};

const blacklistReducer = handleActions(
  {
    [blacklistActions.GET_BLACKLIST_SUCCESS]: (state, action) => {
      const { list } = action.payload;
      return update(state, { blacklist: { $set: list } });
    },
    [blacklistActions.DELETE_BLACKLIST_ITEM_SUCCESS]: (state, action) => {
      cogoToast.success("Data successfully deleted");
      return state;
    },
    [blacklistActions.POST_BLACKLIST_ITEM_SUCCESS]: (state, action) => {
      cogoToast.success("Item created");
      return state;
    },
    [combineActions(
      blacklistActions.GET_BLACKLIST_ERROR,
      blacklistActions.POST_BLACKLIST_ITEM_ERROR,
      blacklistActions.DELETE_BLACKLIST_ITEM_ERROR
    )]: (state, action) => {
      const { message } = action.payload.error.response.data.error;
      cogoToast.error(message);
      return update(state, { error: { $set: message } });
    },
  },
  initialState
);

export default blacklistReducer;
