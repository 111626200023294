import { createActions } from "redux-actions";

const userActions = {
  ...createActions({
    USER_SIGN_IN: user => ({ user }),
    USER_SIGN_OUT: () => {}
  }),

  USER_SIGN_IN: "USER_SIGN_IN",
  USER_SIGN_OUT: "USER_SIGN_OUT"
};

export default userActions;
