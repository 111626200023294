const getPartnersList = (state) => {
  return state.partners.list;
};
const getPartnersCount = (state) => {
  return state.partners.count;
};
const getPartnerId = (state) => {
  return state.partners.partnerID;
};
const getCurrentPage = (state) => {
  return state.partners.currentPage;
};
const getPartnersErrors = (state) => {
  return state.partners.error;
};
const getLimit = (state) => {
  return state.partners.limit;
};

export default {
  getPartnersList,
  getPartnersCount,
  getPartnerId,
  getCurrentPage,
  getPartnersErrors,
  getLimit,
};
