import React from "react";
import styled, { keyframes } from "styled-components";

const loadingAnimation = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

const StyledLoading = styled.div`
  position: fixed;
  transform: translate(-50%, -50%);
  border-radius: 11px;
  padding: 20px 30px;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(24, 24, 28);
  .lds-ring {
    display: flex;
    position: relative;
    width: 80px;
    height: 80px;
    z-index: 9999;
    justify-content: center;
    align-items: center;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 8px;
    border: 4px solid #fff;
    border-radius: 50%;
    animation: ${loadingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const Loading = () => {
  return (
    <StyledLoading>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </StyledLoading>
  );
};

export default Loading;
