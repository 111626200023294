const getIntegrationsList = (state) => {
  return state.integrations.list;
};

const getIntegrationsCount = (state) => {
  return state.integrations.count;
};

const getIntegrationsImagesData = (state) => {
  return state.integrations?.list.map((item) => ({
    name: item.name,
    icon: item.Icon,
  }));
};

export default {
  getIntegrationsList,
  getIntegrationsCount,
  getIntegrationsImagesData,
};
