import {handleActions} from "redux-actions";
import update from "immutability-helper";
import cogoToast from "cogo-toast";

import actions from "./actions";

const initialState = {};

const reducer = handleActions(
    {
        [actions.CREATE_DEMO_RECORDING_ERROR]: (state, action) => {
            const {message} = action.payload.error;
            cogoToast.error(message);
            return update(state, {error: {$set: message}});
        },
        [actions.CREATE_DEMO_RECORDING_SUCCESS]: (state, action) => {
            cogoToast.success("Demo Record created");
            return state;
        },
        [actions.CREATE_DEMO_RECORDING]: (state, action) => {
            cogoToast.loading("Loading", { hideAfter: 0.8 });
            return state;
        },
    },
    initialState
);

export default reducer;
