import moment from "moment";

export const parseDate = (date) => {
  if (date === "1970-01-01T00:00:01Z") return "none";

  return moment(date).format("DD-MM-YYYY HH:mm");
};

export const parseDateToForm = (date) => {
  return moment(date).toDate();
};
