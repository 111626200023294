import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Box } from "./Info";
import { ExtendStyledTable } from "../tables/styles";
import { accountActions, accountSelectors } from "../../redux/accounts";
import { reduxLoaderSelectors } from "../../redux/redux-loader";
import { formatData } from "../../utils/formatter";
import { StyledButton } from "../styled/StyledButton";
import AddProperty from "../modals/AddProperty";

const Container = styled.div`
  display: flex;
  align-items: center;
  & button.table-button {
    height: auto;
    padding: 4px 8px;
    margin-left: 16px;
    font-weight: 600;
  }
  & h2 {
    margin: 0;
  }
  margin-bottom: 16px;
  margin-top: 24px;
`;

const AccountProperties = ({
  fetchAccountProperties,
  accountProps,
  accountID,
  fetchAccountPropertiesState: { isDone },
  deleteAccountPropertyState,
  deleteAccountProperty,
}) => {
  useEffect(() => {
    if (accountProps === undefined) {
      fetchAccountProperties(accountID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openAccountProperty, setOpenAccountProperty] = useState(false);

  const renderTableData = (data) => {
    return data.map((item) => (
      <tr>
        <td>{item.type}</td>
        <td>{item.name}</td>
        <td>{item.value}</td>
        <td>{formatData(item.public) || "-"}</td>
        <td>
          <StyledButton
            as="button"
            className="table-button"
            onClick={() => deleteAccountProperty(accountID, item.name)}
            secondary
            loading
            isLoading={deleteAccountPropertyState.isLoading}
            disabled={deleteAccountPropertyState.isLoading}
          >
            Delete
          </StyledButton>
        </td>
      </tr>
    ));
  };

  return (
    <Box>
      <Container>
        <h2>Properties</h2>
        <StyledButton
          as="button"
          secondary
          className="table-button"
          onClick={() => setOpenAccountProperty(!openAccountProperty)}
        >
          Add
        </StyledButton>
      </Container>

      {accountProps === undefined ? (
        <p>{isDone ? "Failed to fetch :(" : "Loading..."}</p>
      ) : isDone && accountProps?.properties?.length > 0 ? (
        <ExtendStyledTable>
          <thead>
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Value</th>
              <th>Public</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{accountProps.properties.length > 0 && renderTableData(accountProps.properties)}</tbody>
        </ExtendStyledTable>
      ) : (
        <p>No data</p>
      )}
      {openAccountProperty && (
        <AddProperty setOpen={setOpenAccountProperty} isOpen={openAccountProperty} accountID={accountID} />
      )}
    </Box>
  );
};

export default connect(
  // mapStateToProps
  (state, ownProps) => (state) => {
    return {
      accountProps: accountSelectors.getAccountProperties(state),
      fetchAccountPropertiesState: reduxLoaderSelectors.getLoadingState(state, accountActions.getAccountProperties),
      deleteAccountPropertyState: reduxLoaderSelectors.getLoadingState(state, accountActions.deleteAccountProperty),
    };
  },
  // mapDispatchToProps
  (dispatch) => {
    return bindActionCreators(
      {
        fetchAccountProperties: accountActions.getAccountProperties,
        deleteAccountProperty: accountActions.deleteAccountProperty,
      },
      dispatch
    );
  }
)(AccountProperties);
