import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { accountSelectors, accountActions } from "../../redux/accounts";
import Info from "./Info";
import ESstats from "./ESstats";
import LogsStats from "./Logs";
import APIErrors from "./APIErrors";
import Button, { StyledButton } from "../styled/StyledButton";
import QuotasForm from "../modals/QuotasForm";
import DeleteAccount from "../modals/DeleteAccount";
import GeneratedTokens from "../mixins/generatedTokens";
import { MdDeleteForever } from "react-icons/md";
import { BsPlayFill, BsPencil, BsArrowLeftShort } from "react-icons/bs";
import { StyledToolbar } from "../styled/ToolbarStyles";
import Quotas from "../tables/Quotas";
import Statistics from "../tables/Statistics";
import AccountTable from "../tables/Account";
import { AccountInfo } from "../tables/styles";
import UpdateAccount from "../modals/UpdateAccount";
import UpdatePlan from "../modals/UpdatePlan";
import AccountProperties from "./AccountProperties";

import queryString from "query-string";
import { reduxLoaderActions, reduxLoaderSelectors } from "../../redux/redux-loader";

const StyledSection = styled.section`
  display: grid;
  grid-template-columns: 260px 1fr 260px;
  grid-column-gap: 8px;
  margin: 0 auto;
  max-width: 1600px;
  & .content {
    border-radius: 0 8px 8px 0;
    overflow: hidden;
  }
  & .nav-tabs {
    border-bottom: none;
  }
`;

const StyledAccount = styled.div`
  padding: 20px 16px;
  background-color: #252529;
  border-radius: 8px 0 0 8px;
  width: 260px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  & h2 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: #bdbcc9;
    display: block;
    position: relative;

    &::before {
      content: "";
      width: 4px;
      height: 100%;
      background-color: #bdbcc9;
      position: absolute;
      left: -16px;
      top: 0;
      border-radius: 0 4px 4px 0;
    }
  }
`;

const GoBack = styled(Link)`
  border-radius: 50%;
  background-color: #35363c;
  padding: 10px;
  margin-right: 32px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease;
  &:hover {
    background-color: #4663f6;
  }
  & svg {
    font-size: 22px;
  }
`;

export const StyledNavItem = styled(NavItem)`
  background-color: #35363c;
  border: none;
  border-radius: 4px 4px 0 0;
  margin-right: 8px;
  &.nav-item .nav-link {
    padding: 8px 24px;
    font-weight: 600;
    font-size: 14px;
    border: none;
    position: relative;
    color: #b8b8c5;
  }
  &.nav-item .nav-link:hover {
    border: none;
    cursor: pointer;
    outline: none;
  }
  &.nav-item .nav-link.active {
    border: none;
    color: #8598f9;
    background-color: #252529;
  }
`;

export const Content = styled.div`
  min-height: 100%;
  background-color: #252529;
  border-radius: 0 8px 8px 0;
  & .tab-pane {
    height: 100%;
  }
  & div {
    overflow: initial;
    border-radius: 0 8px 8px 0;
  }
`;

const NavBoard = styled(StyledToolbar)`
  & div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${StyledButton} {
      margin-right: 16px;
    }
  }
`;

const WebsiteInfo = styled.div`
  margin-right: 40px;
  & p {
    font-size: 18px;
    padding-right: 16px;
    border-right: 1px solid #aaa;
    margin-bottom: 0;
  }
  & a {
    margin-left: 16px;
    font-size: 18px;
  }
`;

const GeneralInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  & img:first-of-type {
    width: 40px;
    height: 40px;
    border-radius: 0 11px 11px 11px;
    flex-basis: 40px;
  }
  & img:nth-of-type(2) {
    transform: translate(28px, -8px);
    margin-bottom: 8px;
    width: 20px;
    height: auto;
  }
  & .general {
    max-width: 100%;
    & p {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

const tabs = ["Info", "ES statistics", "Properties", "API Errors", "Logs"];
const tabIDs = ["info", "esstats", "properties", "api_errors", "logs"];

const AccountDetailsUI = ({ data, getGhostLogin, ghostLogin, plans, getPlans, pricingModel, addAccountPropertyState: { isDone, isError }, resetAddAccountPropertyState }) => {
  const [activeTab, setActiveTab] = useState("info");
  const [openQuotasForm, setOpenQuotasForm] = useState(false);
  const [openUpdateAccount, setOpenUpdateAccount] = useState(false);
  const [openUpdatePlan, setOpenUpdatePlan] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);

  const search = queryString.parse(window.location.search);

  useEffect(() => {
    if (!plans) getPlans();
  }, [plans, getPlans]);

  useEffect(() => {
    if (isDone && !isError) {
      window.history.pushState("", "", `${window.location.pathname}?tab=properties`);
      resetAddAccountPropertyState();
    } else if (search.tab && tabIDs.find((item) => item === search.tab)) {
      setActiveTab(search.tab);
    } else {
      window.history.pushState("", "", `${window.location.pathname}?tab=${activeTab}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDone]);

  useEffect(() => {
    if (search.tab && tabIDs.find((item) => item === search.tab)) {
      setActiveTab(search.tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { account, agents, analtytics, quotas, websites } = data;
  const { first_name, last_name, login } = agents.filter((item) => item.role === "owner")[0];
  const accountWebsiteSource = login.split("@");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      window.history.pushState("", "", `${window.location.pathname}?tab=${tab}`);
    }
  };
  return (
    <>
      {openQuotasForm && <QuotasForm data={quotas} isOpen={openQuotasForm} setOpen={setOpenQuotasForm} accountID={account.account_id} />}
      {deleteModal && <DeleteAccount isOpen={deleteModal} setOpen={showDeleteModal} accountID={account.account_id} />}
      {openUpdateAccount && <UpdateAccount currency={account.currency} endDate={account.end_date} status={account.status} setOpen={setOpenUpdateAccount} isOpen={openUpdateAccount} accountID={account.account_id} />}
      {openUpdatePlan && <UpdatePlan data={account} setOpen={setOpenUpdatePlan} isOpen={openUpdatePlan} plans={plans} pricingModel={pricingModel} />}

      <NavBoard>
        <div>
          <GoBack to="/">
            <BsArrowLeftShort />
          </GoBack>
          <WebsiteInfo>
            <p>Accounts</p>
            <a href={`https://${accountWebsiteSource[1]}`} rel="noreferrer noopener" target="_blank" style={{ textDecoration: "underline" }}>
              {accountWebsiteSource[1]}
            </a>
          </WebsiteInfo>
          <Button as="button" onClick={(e) => getGhostLogin(login)}>
            <BsPlayFill />
            Ghost login
          </Button>
          {ghostLogin.token && login === ghostLogin.email && <GeneratedTokens ghostLogin={ghostLogin} style={{ marginRight: 16 }} login={login} toast />}
          <Button as="button" onClick={() => setOpenUpdateAccount(!openUpdateAccount)}>
            <BsPencil />
            Update account
          </Button>
          <Button as="button" onClick={() => setOpenQuotasForm(!openQuotasForm)}>
            <BsPencil />
            Update quotas
          </Button>
          <Button as="button" onClick={() => setOpenUpdatePlan(!openUpdatePlan)}>
            <BsPencil />
            Update plan
          </Button>
        </div>
        <Button as="button" className="danger" onClick={() => showDeleteModal(true)}>
          <MdDeleteForever />
          Delete account
        </Button>
      </NavBoard>
      <StyledSection style={{ marginTop: 16 }}>
        <StyledAccount>
          <GeneralInfo>
            <img src={`https://avatar.oxro.io/avatar?name=${first_name}+${last_name}`} alt="avatar" /> <img src={`${process.env.PUBLIC_URL}/images/${analtytics?.country_code?.toLowerCase()}.svg`} alt="flag" />
            <div className="general">
              {first_name && last_name && (
                <AccountInfo>
                  <p>
                    <strong>Name</strong>
                  </p>
                  <p>
                    {first_name} {last_name}
                  </p>
                </AccountInfo>
              )}
              <AccountInfo>
                <p>
                  <strong>E-mail</strong>
                </p>
                <p>{login}</p>
              </AccountInfo>
            </div>
          </GeneralInfo>
          <AccountTable account={account} retention={quotas.quota_retention} />
        </StyledAccount>
        <div className="content">
          <Nav tabs>
            {tabs.map((tab, i) => (
              <StyledNavItem key={tab}>
                <NavLink
                  className={activeTab === `${tabIDs[i]}` ? "active" : null}
                  onClick={() => {
                    toggle(`${tabIDs[i]}`);
                  }}
                >
                  {tab}
                </NavLink>
              </StyledNavItem>
            ))}
          </Nav>
          <Content>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="info">
                <Info websites={websites} agents={agents} properties={quotas} accountID={account.account_id} />
              </TabPane>
              <TabPane tabId="esstats">{activeTab === "esstats" && <ESstats accountID={account.account_id} />}</TabPane>
              <TabPane tabId="properties">{activeTab === "properties" && <AccountProperties accountID={account.account_id} />}</TabPane>
              <TabPane tabId="api_errors">{activeTab === "api_errors" && <APIErrors accountID={account.account_id} />}</TabPane>
              <TabPane tabId="logs">{activeTab === "logs" && <LogsStats accountID={account.account_id} />}</TabPane>
            </TabContent>
          </Content>
        </div>
        <StyledAccount style={{ borderRadius: "0 8px 8px 0" }}>
          <h2>Quotas</h2>
          <Quotas quotas={quotas} />
          <h2 style={{ marginTop: 16 }}>Statistics</h2>
          <Statistics statistics={analtytics} />
        </StyledAccount>
      </StyledSection>
    </>
  );
};

export default connect(
  // mapStateToProps
  (state, ownProps) => (state) => {
    return {
      ghostLogin: accountSelectors.getGhostLoginData(state),
      plans: accountSelectors.getAllPlans(state),
      pricingModel: accountSelectors.getPricingModel(state),
      addAccountPropertyState: reduxLoaderSelectors.getLoadingState(state, accountActions.addAccountProperty),
    };
  },
  // mapDispatchToProps
  (dispatch) => {
    return bindActionCreators(
      {
        getGhostLogin: accountActions.ghostLogin,
        getPlans: accountActions.getPlans,
        resetAddAccountPropertyState: () => reduxLoaderActions.resetReduxLoaderState(accountActions.addAccountProperty),
      },
      dispatch
    );
  }
)(AccountDetailsUI);
